import { dynamicResolution2 } from '~/utils/resolution'

export default {
    dynamicResolution(_state, _getters, rootState) {
        return dynamicResolution2(rootState.period.end, rootState.period.start)
    },

    selectedSiteIds(_state, _getters, rootState) {
        return rootState.entities['carbon-sources'].selectedSites
    },

    isScopesTab(state) {
        return state.tabIndex === '0'
    },

    requestNodeIds: (_state, getters) => (showScope3, getAll) => {
        const nodes = [
            'c-scope-1',
            'c-scope-2',
            ...(showScope3 ? ['c-scope-3'] : []),
            'c-out-of-scope',
        ]
        if (getAll) return nodes
        return getters.isScopesTab
            ? nodes.slice(0, 3)
            : nodes.slice(3)
    },
}
