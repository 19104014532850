
import { cloneDeep, groupBy, partition } from 'lodash'
import CarbonInputGridMultiple from '~/components/carbon/carbon-input-grid-multiple'
import CarbonSourceDetail from '~/components/carbon/source-detail.vue'
import Site from '~/orm/models/Site'
import { arrayToCsv } from '~/utils/file'
import { fileDownload, isDate, isNumeric } from '~/utils/tools'
import { TRACKING_EVENTS } from '~/utils/tracking'

export default {
    name: 'CarbonSourceForm',

    components: {
        CarbonInputGridMultiple,
        CarbonSourceDetail,
    },
    provide() {
        return {
            sourceForm: this,
        }
    },
    props: {
        source: {
            type: Object,
            required: true,
        },
        initialStep: {
            type: Number,
            default: 2,
        },
    },

    data(vm) {
        return {
            step: vm.initialStep,
            rawData: null,
            rawFile: null,
            fileName: '',
            formattedData: [],
            csvLineSeparator: ['\r\n', '\n\r', '\r', '\n'],
            csvColumnSeparator: [';', ',', '\t'],
            csvHasHeaders: false,
            transpose: false,
            mapping: [],
            smartColumnsUsedIndexes: [],
            editMode: false,
            showOverlay: false,
            columnAnalysisProgress: 0,
            cancelAnalysis: false,
            columnsValidation: {},
            manualMode: false,
            units: {},
            loading: false,
            rowsValidation: {},
            csvHeaders: [],
            dialogContentHeight: 0,
            originalData: {
                mapping: [],
                data: [],
            },
        }
    },

    computed: {
        parametersSatisfied() {
            const values = Object.values(this.rowsValidation)
            return !(values && values.length) ? false : values.every(e => e)
        },
        attributes() {
            const { siteId, ...rest } = this.source.attributes
            return { ...rest, ...(siteId && { 'label.site_name': Site.query().find(siteId).name }) }
        },
        columnColors() {
            return this.$vuetify.theme.isDark
                ? ['#515475ED', '#516175ED', '#517075ED']
                : ['#ABADD0ED', '#B5CCECED', '#9CD0DAED']
        },
        inputs() {
            return this.source.restrictedInputs
        },
        getGroupColors() {
            const colors = this.columnColors
            const groupColorMap = {}
            const groupedInputs = [...new Set(this.inputs.map(header => header.group))]
            groupedInputs.forEach((group, index) => { groupColorMap[group] = colors[index % colors.length] })

            return groupColorMap
        },
        requiredGroupColumnCount() {
            return this.inputs.reduce((acc, { group }) => ({ ...acc, [group]: (acc[group] || 0) + 1 }), {})
        },
        requiredGroupColumns() {
            return this.inputs.reduce((acc, { group, key }) => ({ ...acc, [group]: [...(acc[group] || []), key] }), {})
        },
        groupInputs() {
            const groupedInputs = groupBy(this.inputs, 'group')
            return Object.keys(groupedInputs).map(group => ({
                group,
                inputs: groupedInputs[group],
            }))
        },

        mandatoryAndAvailableInputs() {
            const [mandatory, available] = partition(this.groupInputs, ({ group }) => group === 'uniq')
            return [
                {
                    title: 'Mandatory',
                    list: mandatory,
                },
                {
                    title: 'Available Input Types',
                    list: available,
                },
            ]
        },

        columnStyles() {
            const validColumnsByGroup = {}
            this.inputs.forEach((e, k) => {
                const column = this.getCsvColumnByIndex(k) || e.key
                const columnValidationStatus = this.isColumnValid(column)

                if (columnValidationStatus === true) {
                    if (!validColumnsByGroup[e.group]) {
                        validColumnsByGroup[e.group] = []
                    }
                    validColumnsByGroup[e.group].push(e.inputKey)
                }
            })

            return this.inputs.reduce((acc, e, k) => {
                const column = this.getCsvColumnByIndex(k) || e.key
                const columnValidationStatus = this.isColumnValid(column)
                let headerIcon, headerIconColor, message
                const shouldInvalidate = validColumnsByGroup[e.group] && validColumnsByGroup[e.group].length > 1 && this.requiredGroupColumns[e.group] && this.requiredGroupColumns[e.group].includes(e.inputKey) && validColumnsByGroup[e.group].length !== this.requiredGroupColumnCount[e.group]
                const shouldError = validColumnsByGroup[e.group] && validColumnsByGroup[e.group].length >= 1 && this.requiredGroupColumns[e.group] && this.requiredGroupColumns[e.group].includes(e.inputKey) && !validColumnsByGroup[e.group].includes(e.inputKey)
                if (columnValidationStatus === true && !shouldInvalidate) {
                    headerIcon = '$check'
                    headerIconColor = 'success'
                    message = this.$t('pages.carbon.input_grid_column_ok')
                } else if (columnValidationStatus === false || shouldInvalidate) {
                    headerIcon = '$triangleExclamation'
                    headerIconColor = 'warning'
                    message = this.$t('pages.carbon.input_grid_column_not_ok')
                } else if (shouldError) {
                    headerIcon = '$close'
                    headerIconColor = 'error'
                    message = 'Field is required'
                } else {
                    headerIcon = ''
                    headerIconColor = 'default'
                }

                if (!acc[e.group]) acc[e.group] = {}
                acc[e.group][e.inputKey] = { headerIcon, headerIconColor, message }

                return acc
            }, {})
        },

    },

    watch: {
        source: {
            immediate: true,
            handler() {
                this.inputs.forEach(({ key, units }) => {
                    if (units && units.length) {
                        this.units[key] = units.find(u => u.key === 'litres') || units[0]
                    }
                })
                if (this.step === 3 && this.manualMode) {
                    this.initManualInput()
                }
                if (this.step === 2) {
                    this.$nextTick(() => {
                        this.$set(this, 'manualMode', false)
                        this.$set(this, 'csvHeaders', [])
                        this.$set(this, 'columnsValidation', {})
                        this.$set(this, 'formattedData', [])
                        this.$set(this, 'rowsValidation', {})
                        const _this = this
                        if (this.$refs.file) {
                            this.$refs.file.oninput = function() {
                                _this.handleFiles(this.files)
                            }
                        }

                        if (this.$refs.formRow) {
                            this.dialogContentHeight = this.$refs.formRow.clientHeight || 0
                        }
                    })
                }
            },
        },
        step(step) {
            if (step === 2) {
                this.$nextTick(() => {
                    this.$set(this, 'manualMode', false)
                    this.$set(this, 'csvHeaders', [])
                    this.$set(this, 'columnsValidation', {})
                    this.$set(this, 'formattedData', {})
                    this.$set(this, 'rowsValidation', {})
                    const _this = this
                    if (this.$refs.file) {
                        this.$refs.file.oninput = function() {
                            _this.handleFiles(this.files)
                        }
                    }

                    if (this.$refs.formRow) {
                        this.dialogContentHeight = this.$refs.formRow.clientHeight || 0
                    }
                })
            }
        },
    },

    beforeDestroy() {
        this.step = 2
        this.rawData = null
        this.formattedData = null
        document.onkeydown = null
    },

    mounted() {
        const _this = this
        this.$refs.file.oninput = function() {
            _this.handleFiles(this.files)
        }
        document.onkeydown = async e => {
            if (e.key === 'v' && e.ctrlKey) {
                const r = await e.view.navigator.clipboard.readText()
                this.rawData = r
                this.step = 3
                this.rawFile = `data:text/csv;base64,${btoa(r)}`
                this.formatCSVData()
            }
        }
    },

    methods: {
        handleFiles(files) {
            const supportedFileTypes = ['csv'] // only accept CSV
            const reader = new FileReader()
            reader.onload = e => {
                const fullPath = this.$refs.file.value
                if (fullPath) {
                    const startIndex = (fullPath.includes('\\') ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'))
                    const filename = fullPath.substring(startIndex)
                    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                        this.fileName = filename.substring(1)
                    }
                }
                const ext = fullPath.split('.').pop()
                if (ext && !supportedFileTypes.includes(ext.toLowerCase())) {
                    this.$toast.error(this.$t('validation.file_type_csv'), { position: 'top-left' })
                    return
                }
                this.rawFile = e.target.result
                const str = e.target.result.split('base64,')
                this.rawData = atob(str[1])
                this.formatCSVData()
                this.step = 3
            }
            reader.readAsDataURL(files[0])
        },
        formatCSVData() {
            if (!this.rawData) return false
            let pickedLineSeparator
            let pickedColumnSeparator

            // try to guess the line separator
            for (const symbol of this.csvLineSeparator) {
                const re = new RegExp(symbol, 'g')
                const count = (this.rawData.match(re) || []).length
                if (count) {
                    pickedLineSeparator = symbol
                    break
                }
            }

            if (!pickedLineSeparator) {
                console.warn('Could not find line separator')
                return false
            }

            const lines = this.rawData.split(pickedLineSeparator).filter(e => e.length)

            // try to guess the column separator
            for (const symbol of this.csvColumnSeparator) {
                const re = new RegExp(symbol, 'g')
                const count = (lines[0].match(re) || []).length
                if (count) {
                    pickedColumnSeparator = symbol
                    break
                }
            }

            if (!pickedColumnSeparator) {
                console.warn('Could not find column separator')
                return false
            }

            this.formattedData = lines.map(line => line.split(pickedColumnSeparator).map(c => c.trim()))
            // check for empty or incomplete lines
            this.formattedData = this.formattedData.filter((e, k) => e.filter(o => o.length > 0).length >= e.length - 2)

            // transpose matrix
            const [headers, ...rows] = this.formattedData
            const cleanHeaders = headers.map(h => h.trim())
            const formattedRows = rows.map(row => {
                const obj = {}
                cleanHeaders.forEach((header, hIndex) => {
                    const key = header.toLowerCase()
                    obj[key] = {
                        text: row[hIndex],
                        columns: ['text'],
                        group: key,
                        type: 'string',
                        inputKey: key,
                        index: hIndex,
                        readonly: false,
                        placeholder: '',
                    }
                })
                return obj
            })
            const mapping = cleanHeaders.map(h => {
                const headerKey = h.toLowerCase()
                return {
                    key: headerKey,
                    name: h,
                    group: headerKey,
                    type: 'string',
                    unit: null,
                    units: [],
                    readonly: false,
                }
            })

            this.formattedData = formattedRows
            this.mapping = mapping
            this.originalData = cloneDeep({ mapping, data: formattedRows })
            this.csvHeaders = cleanHeaders
        },
        updateMapping(columnIndex, newProperties) {
            if (!this.mapping.length || !this.mapping[columnIndex]) return
            this.$set(this.mapping, columnIndex, newProperties)
        },
        updateFormattedDataProperties(column, newProperties) {
            this.formattedData.forEach((row, index) => {
                if (row[column]) {
                    const newValue = {
                        type: newProperties.type,
                        group: newProperties.group,
                        columns: ['text'],
                        inputKey: newProperties.key,
                        ...(newProperties.options && { options: newProperties.options }),
                        text: row[column].text,
                        index: row[column].index,
                    }
                    this.$set(this.formattedData[index], column, newValue)
                }
            })
        },
        updateValidationResults(column, validationResults) {
            validationResults.forEach((isValid, index) => {
                if (!this.columnsValidation[index]) {
                    this.$set(this.columnsValidation, index, {})
                }
                this.$set(this.columnsValidation[index], column, !!isValid)
                this.validateRow(column, index)
            })
        },
        getCsvColumnByIndex(index) {
            if (this.csvHeaders[index]) return this.csvHeaders[index].toLowerCase().trim()
            return false
        },
        rollbackMappingAndFormattedData(columnIndex) {
            const originalValue = this.originalData.mapping[columnIndex] || { key: '', name: '', group: '', type: 'string', unit: null, units: [] }
            this.$nextTick(() => {
                this.originalData.data.forEach((r, i) => {
                    const originalFormattedValue = r[originalValue.key] || {}
                    this.$set(this.formattedData[i], originalValue.key, originalFormattedValue)
                    this.removeValidColumn(i, originalValue.key)
                })
                this.updateMapping(columnIndex, originalValue)
            })
        },
        async onMappingUpdate({ col, columnIndex, value }) {
            const column = this.getCsvColumnByIndex(columnIndex) || col
            try {
                this.cancelAnalysis = false
                this.showOverlay = true
                const dataToValidate = this.formattedData.map(f => f[column].text)
                const valid = await this.validateColumnAsync(dataToValidate, value, 0)
                const existingIndex = this.mapping.findIndex(m => m && m.key === value.key && m.group === value.group)
                if (existingIndex !== -1 && existingIndex !== columnIndex) this.rollbackMappingAndFormattedData(existingIndex)
                this.updateMapping(columnIndex, value)
                this.updateFormattedDataProperties(column, value)
                this.updateValidationResults(column, valid)
                this.showOverlay = false
                this.cancelAnalysis = false
            } catch (e) {
                this.showOverlay = false
            }
        },
        validateColumnAsync(data, input, i) {
            const result = []
            this.columnAnalysisProgress = 0
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (this.cancelAnalysis) {
                        this.columnAnalysisProgress = 0
                        clearInterval(interval)
                        reject(new Error('Analysis canceled.'))
                    }
                    this.columnAnalysisProgress = Math.ceil(i / data.length * 100)
                    if (data[i]) {
                        result.push(this.validateSingleValue(data[i], input))
                        i++
                    } else {
                        clearInterval(interval)
                        resolve(result)
                    }
                }, 1)
            })
        },
        validateSingleValue(value, opt) {
            const { type, units: maybeUnits, options: maybeEnumOptions } = opt
            switch (type) {
                case 'date':
                    return isDate(value)
                case 'number':
                    return isNumeric(value)
                case 'unit':
                    return !!(maybeUnits && maybeUnits.find(u => u.key === value))
                case 'enum':
                    return !!(maybeEnumOptions && maybeEnumOptions.find(e => e.key === value))
                default:
                    return true
            }
        },
        onCellEdit({ row, col, value, index: cellIndex, key: inputKey }) {
            const column = this.getCsvColumnByIndex(cellIndex) || col
            const t = cloneDeep(this.formattedData)
            t[row][column].text = value
            this.$set(this, 'formattedData', t)
            const findInput = this.mapping.find(i => i.key === inputKey)
            if (findInput?.type && value && value.length) {
                this.validColumn(row, column, value, findInput)
            } else if (!value) {
                this.removeValidColumn(row, col)
            }
            // add a new row if last row was changed
            if (this.manualMode && value !== '' && row === this.formattedData.length - 1) {
                this.formattedData.push(this.addNewRow())
            }
        },
        addNewRow() {
            return this.inputs.reduce((row, input, index) => ({
                ...row,
                [input.key]: {
                    text: null,
                    columns: ['text'].filter(f => f),
                    group: input.group,
                    type: input.type,
                    inputKey: input.key,
                    ...(input.options && { options: input.options }),
                    index,
                    readonly: false,
                },
            }), {})
        },
        initManualInput() {
            const initialRows = this.dialogContentHeight > 0 ? Math.floor((this.dialogContentHeight - 33) / 27) : 10
            this.formattedData = Array.from({ length: initialRows }, () => this.addNewRow())
            this.mapping = this.inputs
            this.$set(this, 'csvHeaders', [])
            this.$set(this, 'manualMode', true)
            this.$set(this, 'columnsValidation', {})
            this.$set(this, 'rowsValidation', {})
            this.step = 3
        },
        async saveData() {
            const dataArray = this.formattedData.map((row, rowID) => {
                return Object.fromEntries(Object.entries(row).filter(([column]) => this.columnsValidation[rowID] && this.columnsValidation[rowID][column]))
            })

            const payload = {
                data: dataArray.map((e, rowID) => {
                    if (!this.rowsValidation[rowID]) return null
                    const columns = Object.entries(e)
                    const findTimeStamp = columns.find(([, detail]) => detail.inputKey === 'timestamp')
                    const timestamp = isDate(findTimeStamp[1].text).dt.toISOString()
                    const inputs = []
                    if (this.manualMode) {
                        columns.forEach(([col, detail], k) => {
                            if (detail.inputKey === 'timestamp' || !this.columnsValidation[rowID][col]) return
                            const mappingHas = this.mapping.find(i => i.key === detail.inputKey)
                            if (mappingHas) {
                                inputs.push({
                                    key: mappingHas.key,
                                    unit: this.units[mappingHas.key]?.key,
                                    value: detail.text,
                                })
                            }
                        })
                    } else {
                        const validGroups = new Set()
                        columns.forEach(([col, detail], cellIndex) => {
                            const column = this.getCsvColumnByIndex(cellIndex) ?? col.key
                            if (detail.group && this.columnsValidation[rowID][column]) validGroups.add(detail.group)
                        })

                        if (validGroups.size === 0 || !validGroups.has('uniq')) return null
                        const selectedGroup = [...validGroups].find(group => group !== 'uniq')
                        validGroups.clear()
                        validGroups.add('uniq')
                        if (selectedGroup) validGroups.add(selectedGroup)
                        columns.forEach(([col, detail]) => {
                            if (detail.inputKey === 'timestamp' || !validGroups.has(detail.group)) return
                            const mappingHas = this.mapping.find(i => i.key === detail.inputKey)
                            if (mappingHas) {
                                inputs.push({
                                    key: mappingHas.key,
                                    unit: this.units[mappingHas.key]?.key,
                                    value: detail.text,
                                })
                            }
                        })
                    }
                    return {
                        timestamp,
                        inputs,
                    }
                }).filter(f => f),
            }

            /* const payload = {
                data: dataArray.map((e, rowID) => {
                    if (!this.rowsValidation[rowID]) return null
                    const columns = Object.entries(e)
                    const findTimeStamp = columns.find(([, detail]) => detail.inputKey === 'timestamp')
                    const timestamp = isDate(findTimeStamp[1].text).dt.toISOString()
                    const inputs = []
                    columns.forEach(([col, detail], k) => {
                        if (detail.inputKey === 'timestamp' || !this.columnsValidation[rowID][col]) return
                        const mappingHas = this.mapping.find(i => i.key === detail.inputKey)
                        if (mappingHas) {
                            inputs.push({
                                key: mappingHas.key,
                                unit: this.units[mappingHas.key]?.key,
                                value: detail.text,
                            })
                        }
                    })
                    return {
                        timestamp,
                        inputs,
                    }
                }).filter(f => f),
            } */

            if (!this.manualMode && this.rawFile) {
                payload.file = this.rawFile
                payload.meta = {
                    filename: this.fileName,
                }
            }

            this.loading = true
            try {
                await this.source.uploadData(payload)
                this.$api.tracking.addToBuffer({ type: TRACKING_EVENTS.ASSETS_ADD_DATA })
                this.$toast.success('Data saved')
                this.$eventBus.$emit('on-data-change')
                this.$emit('cancel')
            } catch (err) {
                const errorMsg = this.$_.get(err, 'response.data.message', 'Something went wrong')
                this.$toast.error(errorMsg)
            } finally {
                this.loading = false
            }
        },
        onUnitAssign({ key, unit }) {
            this.$set(this.units, key, unit)
        },

        /**
         * Get an example value for input
         * - useful when building the csv template
         *
         * @param {Object}  input
         *
         * @return {String}
         */
        inputExampleValue(input) {
            let example = ''
            switch (input.type) {
                case 'date':
                    example = new Date().toLocaleDateString('en-GB')
                    break
                case 'number':
                    example = '0.00'
                    break
                case 'enum':
                    example = input.options[0].name
                    break
            }
            return example
        },

        /**
         * Download CSV Template
         *
         * @return {Void} downloads csv template
         */
        downloadCsvTemplate() {
            const inputs = this.inputs
            if (!inputs) {
                return
            }
            const csvItem = {}
            inputs.forEach(input => {
                let key = input.name
                if (input.canonical) {
                    if (input.canonical.includes('(')) {
                        key += ` - ${input.canonical}`
                    } else {
                        key += `(${input.canonical})`
                    }
                }
                csvItem[key] = this.inputExampleValue(input)
            })
            const csvItems = [csvItem]

            try {
                const csv = arrayToCsv(csvItems)
                const timestamp = new Date().valueOf()
                const assetName = this.$_.snakeCase(this.source.name)
                const fileName = `${assetName}_data_csv_template_${timestamp}.csv`
                fileDownload(csv, fileName)
            } catch (err) {
                console.warn('Download csv template error: ', err.message)
            }
        },
        validateAllRows() {
            return this.rowsValidation.every(Boolean)
        },
        validColumn(row, col, value, input) {
            const isColumnValid = this.validateSingleValue(value, input)
            if (!this.columnsValidation[row]) this.$set(this.columnsValidation, row, {})
            this.$set(this.columnsValidation[row], col, isColumnValid)
            this.validateRow(col, row)
        },
        removeValidColumn(row, col) {
            if (!this.columnsValidation[row] || this.columnsValidation[row][col] === undefined) return false
            this.$delete(this.columnsValidation[row], col)
            this.validateRow(col, row)
        },
        validateRow(_cell, rowID) {
            const row = this.formattedData[rowID]
            if (!row) return false
            const groupInputs = groupBy(row, 'group')
            const { uniq, ...restGroups } = groupInputs
            let uniqValid = !!uniq ?? !!uniq.length ?? false
            for (const col of (uniq ?? [])) {
                const column = this.getCsvColumnByIndex(col.index) || col.inputKey
                uniqValid = this.columnsValidation[rowID] && this.columnsValidation[rowID][column]
            }

            let otherGroupValid = false
            const otherGroups = Object.keys(restGroups)
            let filledGroup = null

            for (const group of otherGroups) {
                let validColumnCount = 0
                let hasAnyFilled = false
                let allRequiredColumnsValid = true
                for (const col of groupInputs[group]) {
                    const column = this.getCsvColumnByIndex(col.index) || col.inputKey
                    if (this.columnsValidation[rowID] && this.columnsValidation[rowID][column]) {
                        validColumnCount++
                        hasAnyFilled = true
                    } else if (this.requiredGroupColumns[group] && this.requiredGroupColumns[group].includes(col.inputKey) && allRequiredColumnsValid) {
                        allRequiredColumnsValid = false
                    }
                }

                if (hasAnyFilled && !filledGroup) filledGroup = group
                if (validColumnCount >= this.requiredGroupColumnCount[group] && allRequiredColumnsValid) {
                    otherGroupValid = true
                    filledGroup = group
                    break
                }
            }

            let allRequiredColumnsSelected = true
            if (filledGroup && !this.manualMode) {
                otherGroups.forEach(group => {
                    if (!this.requiredGroupColumns[group]) return
                    allRequiredColumnsSelected = this.requiredGroupColumns[group].every(columnKey => {
                        const column = this.getCsvColumnByIndex(columnKey) || columnKey
                        return this.columnsValidation[rowID] && this.columnsValidation[rowID][column]
                    })
                })
            }

            otherGroupValid = otherGroupValid && allRequiredColumnsSelected

            if (this.manualMode) {
                const requiredColumns = this.inputs.filter(i => i.group === filledGroup)
                otherGroups.forEach(group => {
                    groupInputs[group].forEach(col => {
                        const column = this.getCsvColumnByIndex(col.index) || col.inputKey
                        if (filledGroup && filledGroup !== group) {
                            row[column].readonly = true
                            row[column].placeholder = 'Field is not required'
                        } else {
                            row[column].readonly = false
                            row[column].placeholder = null
                            if (requiredColumns && requiredColumns.length) {
                                requiredColumns.forEach(c => {
                                    if (this.columnsValidation[rowID]) {
                                        const inValid = typeof this.columnsValidation[rowID][c.key] === 'undefined' || !this.columnsValidation[rowID][c.key]
                                        row[c.key].placeholder = inValid ? 'Fill in the required field' : null
                                    }
                                })
                            }
                        }
                    })
                })
            }

            this.$set(this.rowsValidation, rowID, uniqValid && otherGroupValid)
        },
        isColumnValid(column) {
            const validations = this.columnsValidation
            if (Object.keys(validations).length === 0) return null
            let columnExists = false
            for (const rowID in validations) {
                if (Object.prototype.hasOwnProperty.call(validations[rowID], column)) {
                    columnExists = true
                    if (validations[rowID][column] === false) {
                        return false
                    }
                }
            }

            if (!columnExists) return null
            return true
        },
    },
}
