/* eslint-disable no-unreachable-loop */
import { unionBy, remove, compact, uniq, intersectionBy, sortBy, isEqual } from 'lodash'
import { consolidateEntities, getIds } from '~/utils/entities'
import ParametersPreset from '~/orm/models/ParametersPreset'
import Unit from '~/orm/models/Unit'

export default {
    /**
     * Filter selected clamps
     * so that we don't include both parents and children
     * logic as per CLAL-385 ticket requirements
     *
     * @returns {Array} clamps
     */
    consolidatedClamps(_state, getters, rootState) {
        const { period } = rootState
        const pipes = getters.realSelectedClamps
        return consolidateEntities(pipes, period)
    },

    /**
     * Filtered pipes IDs
     *
     * @returns {Array} pipeIds
     */
    consolidatedClampsIds(_state, getters) {
        return getIds(getters.consolidatedClamps)
    },

    /**
     * Real selected pipes
     * > in the case of virtual pipes
     *   we want to return their children instead
     *
     * @param {Object} state
     * @param {Object} getters
     * @param rootState
     * @param rootGetters
     */
    realSelectedClamps(_state, getters, _rootState, rootGetters) {
        let selectedClamps = [...getters.selectedClamps]
        const virtualClamps = remove(selectedClamps, c => c.$id.includes('V'))
        for (const vc of virtualClamps) {
            const presetId = parseInt(vc.$id.replace('V', ''))
            const preset = rootGetters['entities/presets/find'](presetId)
            selectedClamps = unionBy(selectedClamps, preset.clampsChildren.filter(c => !c.$id.includes('V')), '$id')
        }
        return selectedClamps
    },

    selectedSiteIds(_state, getters) {
        return compact([...new Set(getters.realSelectedClamps.map(c => c.siteId))])
    },

    supported(state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/meters/allByCommodity'](state.commodity).length > 0
    },

    unit(state) {
        return state.selectedUnit || state.initialUnit
    },

    homeTabHasChanges(state, getters, rootState) {
        return rootState['entities-manager'].activeEntities.all.length ||
            !isEqual(
                [...rootState['entities-manager'].selectedEntities.all].sort(),
                [...getters.initialTreeViewItems(state.tabIndex !== '0') ?? []].sort(),
            )
    },

    virtualTabHasChanges(_state, _getters, rootState) {
        return rootState['entities-manager'].activeEntities.virtual.length ||
            rootState['entities-manager'].selectedEntities.virtual.length
    },

    hasChanges(state, getters) {
        return (state.tabIndex === '0' && getters.homeTabHasChanges) ||
            (state.tabIndex === '1' && getters.virtualTabHasChanges)
    },

    preset(state) {
        return ParametersPreset.find(state.selectedParametersPreset)
    },

    selectedParameters(state, getters) {
        return state.selectedParameterKeys
            .filter(key => getters.preset?.keysOnly ? getters.preset.keysOnly.includes(key) : false)
            .map(key => Unit.find(key))
    },

    availableSelectedUnit(state, getters) {
        if (getters.preset?.keysOnly.includes(state.selectedUnit)) {
            return state.selectedUnit
        }
    },
    activeClamps(_state, getters, _rootState, rootGetters) {
        return rootGetters['entities-manager/activeClamps'](getters.tabId)
    },

    selectedClamps(_state, getters, _rootState, rootGetters) {
        return rootGetters['entities-manager/selectedClamps'](getters.tabId)
    },
    tabId(state) {
        return state.tabIndex === '0' ? 'all' : 'virtual'
    },
    isMinPF(state) {
        return Boolean(
            state.selectedPoint &&
            state.selectedUnit === 'pf' &&
            state.selectedPoint.scope === 'min' &&
            state.selectedPoint.datasetId.slice(0, 2) === 'pf',
        )
    },
    availableParameter(_state, getters) {
        return getters.preset.parameters.find(unit => getters.selectedClamps.every(c => c.supportsUnit(unit)))?.key
    },

    virtualTreeViewItems(state) {
        return state.treeItems[state.commodity].virtual
    },

    openVirtualTreeViewItems(state, getters) {
        return getters.virtualTreeViewItems.map(i => i.euid)
    },

    treeViewItems(state) {
        return state.treeItems[state.commodity].all
    },

    openTreeViewItems(_state, getters) {
        const items = []
        function recursivePush(item, depth) {
            if (depth >= 20 || !item.euid) {
                return
            }
            items.push(item.euid)
            if (item.children && item.children.length > 0) {
                recursivePush(item.children[0], depth + 1)
            }
        }
        recursivePush(getters.treeViewItems[0] || {}, 0)
        return items
    },

    isVirtual(state) {
        return state.tabIndex === '1'
    },

    virtualOrAll: (_state, getters) => (isVirtual = false) => {
        return isVirtual ? getters.virtualTreeViewItems : getters.treeViewItems
    },

    initialTreeViewItems: (_state, getters) => (isVirtual = false) => {
        const items = []
        let hasMain = false
        let hasSiteOrPreset = false
        for (const customer of getters.virtualOrAll(isVirtual)) {
            for (const site of customer.children) {
                // in the case of virtual items, the site is the preset
                if (isVirtual) {
                    items.push(site.euid)
                    hasSiteOrPreset = true
                    break
                }
                for (const meter of site.children) {
                    if (meter.activatable && !meter.toggleChildrenOnClick) {
                        hasMain = true
                        items.push(meter.euid)
                        break
                    } else if (meter.children.length > 0) {
                        for (const clamp of meter.children) {
                            if (clamp.activatable) {
                                items.push(clamp.euid)
                                break
                            } else if (clamp.children.length > 0) {
                                if (!clamp.activatable) {
                                    items.push(clamp.euid, meter.euid, site.euid, customer.euid)
                                    items.push(...clamp.children.map(({ euid }) => euid))
                                } else {
                                    items.push(clamp.euid)
                                    break
                                }
                            }
                        }
                        break
                    }
                }
                if (hasMain && site.children.length === 1) {
                    hasSiteOrPreset = true
                    items.push(site.euid)
                }
                break
            }
            if (hasSiteOrPreset && customer.children.length === 1) items.push(customer.euid)
            break
        }
        return uniq(items)
    },

    sitesTreeviewItems: (_state, getters) => (siteIds = []) => {
        const customer = getters.treeViewItems[0]
        const allSites = customer.children
        const sites = allSites.filter(s => siteIds.includes(s.id))
        const euids = sites.map(site => ([
            site.euid,
            ...site.children.map(c => c.euid),
        ])).flat()
        if (sites.length === allSites.length) {
            euids.push(customer.euid)
        }
        return euids
    },

    selectedClampsIds(_state, getters) {
        return getters.selectedClamps.map(c => c.id)
    },

    clampWithOldestStartTime(_state, getters, _rootState, rootGetters) {
        const clamps = []
        getters.selectedClamps.forEach(clamp => {
            if (clamp.virtual) {
                clamps.push(...clamp.siblings)
            } else {
                clamps.push(clamp)
            }
        })
        return sortBy(intersectionBy(clamps, rootGetters['entities/clamps/startTimeSortedList'], 'id'), ['startTime'])[0]
    },

    clampWithMostRecentEndTime(state, _getters, _rootState, rootGetters) {
        return rootGetters.rankingCommodity(state.commodity)
    },
}
