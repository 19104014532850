
import { cloneDeep } from 'lodash'
import EditableGrid from './mixins/editable-grid'
export default {
    mixins: [EditableGrid],
    inject: ['sourceForm'],
    props: {
        value: {
            type: Array,
            required: true,
        },
        csvHeaders: {
            type: Array,
            required: true,
        },
        manualMode: {
            type: Boolean,
            required: true,
        },
        columnColors: {
            type: Object,
            required: true,
        },
        expectedColumns: {
            type: Array,
            required: true,
        },
        mapping: {
            type: Array,
            required: true,
        },
        columnsValidation: {
            type: Object,
            required: true,
        },
        units: {
            type: Object,
            required: true,
        },
        rowValid: {
            type: Object,
            required: true,
        },
        getCsvColumnByIndex: {
            type: Function,
            required: true,
        },
        columnStyles: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            transposedData: [],
            userHeaders: [],
            errors: {},
            editedCells: {},
            cellBeingEdited: null,
            tableOpacity: 0,
            headersGridTemplateColumns: null,
        }
    },
    computed: {
        columnMargin() {
            return this.mapping.reduce((acc, item, index, array) => {
                const column = this.getCsvColumnByIndex(index) || item.key
                acc[column] = !(index < array.length - 1 && item.group === array[index + 1].group)
                return acc
            }, {})
        },
    },
    watch: {
        mapping: {
            deep: true,
            handler() {
                this.getTransposedData()
            },
        },
        value: {
            deep: true,
            handler() {
                this.getTransposedData()
            },
        },
    },
    mounted() {
        this.getTransposedData()
        this.headersGridTemplateColumns = `32px 160px ${'minmax(160px, 280px) '.repeat(Object.keys(this.mapping).length - 1).trim()}`
    },
    methods: {
        getTransposedData() {
            this.tableOpacity = 1
            const t = cloneDeep(this.value)
            this.userHeaders = this.csvHeaders
            this.transposedData = t.map(item =>
                Object.fromEntries(
                    Object.entries(item).map(([key, value], idx, arr) =>
                        [key, { ...value, addMargin: !(idx < arr.length - 1 && value.group === arr[idx + 1][1].group) }],
                    ),
                ),
            )
        },
        onKeyDown(e) {
            if (e.keyCode === 9) {
                e.preventDefault()
            }
            const currentRow = Number(e.target.dataset.row)
            const currentColName = e.target.dataset.column
            let nextRow = currentRow
            let nextColName = currentColName

            const columnNames = Object.keys(this.transposedData[0])
            const currentColIndex = columnNames.indexOf(currentColName)

            switch (e.keyCode) {
                case 13: // Enter
                    nextRow = currentRow + 1
                    break
                case 38: // Up
                    nextRow = currentRow > 0 ? currentRow - 1 : 0
                    break
                case 9: // Tab
                case 39:// Right
                    if (currentColIndex === columnNames.length - 1) {
                        nextColName = columnNames[0]
                        nextRow = currentRow + 1
                    } else {
                        nextColName = columnNames[currentColIndex + 1]
                    }
                    break
                case 40: // Down
                    nextRow = currentRow < this.transposedData.length - 1 ? currentRow + 1 : currentRow
                    break
                case 37: // Left
                    nextColName = currentColIndex > 0 ? columnNames[currentColIndex - 1] : columnNames[0]
                    break
                default:
                    return
            }

            if (nextRow >= 0 && nextRow < this.transposedData.length) {
                e.target.blur()
                setTimeout(() => {
                    this.makeCellEditable(nextColName, nextRow)
                }, 10)
            }
        },
        onParameterSelect(col, columnMeta, columnIndex) {
            this.$emit('update:mapping', { col, columnIndex, value: columnMeta })
        },
        getBackgroundColor(group) {
            return this.columnColors[group]
                ? this.columnColors[group]
                : this.$vuetify.theme.themes[this.$vuetify.theme.isDark
                    ? 'dark'
                    : 'light'
                ]['dialog-content-background']
        },
        isColumnValid(column) {
            const validations = this.columnsValidation
            if (Object.keys(validations).length === 0) return null
            let columnExists = false
            for (const rowID in validations) {
                if (Object.prototype.hasOwnProperty.call(validations[rowID], column)) {
                    columnExists = true
                    if (validations[rowID][column] === false) {
                        return false
                    }
                }
            }

            if (!columnExists) return null
            return true
        },

        onUnitSelect(rowIndex, columnIndex, item) {
            this.$emit('update:cell', {
                row: rowIndex,
                col: columnIndex,
                value: item.key,
                key: columnIndex,
            })
        },
        enumValue(options, cell) {
            const found = options.find(({ key, name }) => {
                key = key.toLowerCase()
                name = name.toLowerCase()
                cell = cell.toLowerCase()
                if (key === cell || name === cell) {
                    return true
                }
                return false
            })
            if (found) {
                return found.key
            }
            return options[0].key
        },
        onUnitChange(column, unit) {
            this.$emit('on-unit-assign', { ...column, unit })
        },
        isRowValid(rowID) {
            return this.rowValid[rowID] || false
        },
    },
}
