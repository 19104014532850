
import { startCase } from 'lodash'
import Site from '~/orm/models/Site'

export default {
    name: 'CarbonSourceDetail',
    props: {
        source: {
            type: Object,
            required: true,
        },
    },
    computed: {
        attributes() {
            const required = {}
            const advanced = {}
            const [requiredAttr, advancedAttr] = this.$_.partition(this.source.parent.attributes, 'required')
            const requiredAttributes = new Set(requiredAttr.map(r => r.key))
            const advancedAttributes = new Set(advancedAttr.map(r => r.key))
            Object.entries(this.source.attributes).forEach(([key, value]) => {
                if (requiredAttributes.has(key)) {
                    required[key] = value
                } else if (advancedAttributes.has(key)) {
                    advanced[key] = value
                }
            })
            const { siteId, ...rest } = required
            return { required: { ...rest, ...(siteId && { 'label.site_name': Site.query().find(siteId).name }) }, advanced }
        },
    },
    methods: {
        formatKey(key) {
            return startCase(key)
        },
    },
}
