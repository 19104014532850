import Vue from 'vue'
import { omit } from 'lodash'
import moment from '~/composables/useMoment'

export default class PeriodService {
    initialState = {
        start: moment().subtract(1, 'month').startOf('h').valueOf(),
        end: moment().startOf('h').valueOf(),
        min: moment().subtract(3, 'years').startOf('h').valueOf(),
        max: moment().subtract(6, 'hours').startOf('h').valueOf(),
    }

    #timer = null
    #periodResolver = null
    #duration = 0
    #period = {}
    label = Vue.observable({ start: null, end: null })
    labelChanged = Vue.observable({ value: null })

    constructor() {
        this.#period = this.initialState
        this.label.start = this.#period.start
        this.label.end = this.#period.end
    }

    updateInit(payload = {}) {
        if (payload?.min) {
            payload.min = moment(payload.min).valueOf()
        }

        if (payload?.max) {
            payload.max = moment(payload.max).valueOf()
        }
        this.#period = { ...this.#period, ...payload }
    }

    updatePeriodWithDebounce(period, periodResolver = null, duration = 0) {
        this.#duration = duration
        this.#periodResolver = periodResolver
        this.updateLabel(period)
        this.cancelTimer()
        if (duration) this.#setTimer()
        else this.#resolvePeriod()
    }

    updateLabel(period) {
        // for certain period modifiers we want to reset period interval
        if (['chart', 'datetimepicker'].includes(period.by)) {
            period.interval = null
        }
        const prevPeriod = omit(this.#period, typeof period.interval !== 'undefined' ? 'by' : ['by', 'interval'])
        this.#period = { ...prevPeriod, ...period }
        const validPeriod = this.#validRange()
        if (!validPeriod.start && !validPeriod.end) return false
        this.label.start = validPeriod.start
        this.label.end = validPeriod.end
        this.#period = validPeriod
        this.labelChanged.value = Date.now()
    }

    cancelTimer() {
        if (this.#timer) {
            clearTimeout(this.#timer)
            this.#timer = null
        }
    }

    #setTimer() {
        this.#timer = setTimeout(() => this.#resolvePeriod(), this.#duration)
    }

    #resolvePeriod() {
        if (this.#periodResolver) {
            this.#periodResolver(this.#period)
        }
    }

    #validRange() {
        const clonePeriod = this.#period
        if (clonePeriod.start && clonePeriod.end) {
            clonePeriod.start = moment.max(moment(clonePeriod.start), moment(clonePeriod.min))
            clonePeriod.end = moment.min(moment(clonePeriod.end), moment(clonePeriod.max))
        }
        //  when start is after end
        if (clonePeriod.start?.isAfter(clonePeriod.end)) {
            // if interval is 'ytd' => start is 1st Jan
            // else since if not ytd, this should happen only on page load,
            // we keep the logic of having the start date a month before as the initial state
            const _start = clonePeriod.interval === 'ytd'
                ? moment(clonePeriod.start).set('year', clonePeriod.end.year()).startOf('year')
                : moment(clonePeriod.end).subtract(1, 'month').startOf('h')

            clonePeriod.start = moment.max(_start, moment(clonePeriod.min)).valueOf()
        }
        return { ...clonePeriod, start: clonePeriod.start.valueOf(), end: clonePeriod.end.valueOf() }
    }

    servicePeriodState() {
        return this.#period
    }
}
