/* Models */
import { Model } from '@vuex-orm/core'
import { find, groupBy, orderBy, uniq } from 'lodash'
import Site from './Site'
import Clamp from './Clamp'
import Contract from './Contract'

/* Utils */
export default class Meter extends Model {
    static entity = 'meters'

    static isParamsDynamic = true

    static apiConfig = {
        dataKey: 'data',
        url: 'meters',
        dataTransformer({ data: { data } }) {
            return data ?? []
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            siteId: this.attr(null),
            icon: this.attr('$calculator'),
            name: this.attr(''),
            address: this.attr(null),
            installDate: this.attr(null),
            mprn: this.attr(null),
            meterNumber: this.attr(null),
            clamps: this.hasMany(Clamp, 'meterId'),
            editable: this.boolean(true),
            commodity: this.attr(null),
            // Mix Meter ORM
            gspGroup: this.attr('_F'),
            kvaThreshold: this.number(300),
            supplier: this.attr(null),
            dno: this.attr(null),
            dc: this.attr(null),
            da: this.attr(null),
            mop: this.attr(null),
            mpanTop: this.attr(''),
            mpanBottom: this.attr(''),
            serialNumber: this.attr(null),
            connection: this.number(1),
        }
    }

    get entity() {
        return Meter.entity
    }

    get meterId() {
        return this.id
    }

    /**
     * this function is not good enough.
     * It should check the following and not just the group:
     * GSP
     * LLFC (last 3 chars of mpan top)
     * PC (first two chars of mpan top)
     * Dist ID (first two chars of mpan bottom)
     * @param meters
     * @returns {boolean}
     */
    static haveSameGroup(meters = []) {
        const gspGroups = meters.map(meter => meter.gspGroup)
        return uniq(gspGroups).length === 1
    }

    static haveDUOS(meters = []) {
        return meters.every(m => {
            return m.liveContract && m.liveContract.passed_through_elements && m.liveContract.passed_through_elements.includes('DUOS_RED')
        })
    }

    static haveDifferentDayNightRates(meters = []) {
        return meters.every(m => {
            return m.liveContract && m.liveContract.day_rate && m.liveContract.night_rate && m.liveContract.day_rate !== m.liveContract.night_rate
        })
    }

    get mainIncomer() {
        return find(this.children, { isMainIncomer: true })
    }

    get children() {
        let clamps = this.clamps
        if (clamps.length === 0) {
            clamps = this.getChildren()
        }
        return orderBy(clamps, ['childrenCount', 'totalKwData'], ['desc', 'desc'])
    }

    get fullName() {
        return `${this.parent.name} [${this.meterNumber}]`
    }

    /**
     * Get site
     */
    get site() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
    * Get meter parent
    */
    get parent() {
        return Site.query().whereId(this.siteId).first()
    }

    /**
    * Get meter siblings
    * Other meters under the same parent
    */
    get siblings() {
        return Meter.query()
            .where(record => {
                return record.siteId === this.siteId &&
                    record.meterId !== this.meterId
            })
            .get()
    }

    /**
    * Get relationships
    */
    get relationships() {
        // site
        const site = this.parent
        // clamps
        const clamps = Clamp.query()
            .where('meterId', this.meterId)
            .where('commodity', this.commodity)
            .get()

        return {
            site,
            clamps,
        }
    }

    get contracts() {
        return Contract.query().where(c => c.mpan === this.mpanBottom || c.meter_number === this.mpanBottom || c.meter_number === this.meterNumber).get()
    }

    get liveContract() {
        return Contract.query().where(c => (c.mpan === this.mpanBottom || c.meter_number === this.meterNumber) && c.is_live === true).first()
    }

    get ragConfiguration() {
        return (async() => {
            const rag = await this.$store().$api.electricity.getRag(this.gspGroup)
            return rag && rag.length && groupBy(rag, 'name')
        })()
    }

    getName({ showMpan } = {}) {
        if (showMpan) return this.meterNumber || this.mpanBottom
        return this.name && !this.name.toLowerCase().includes('test')
            ? this.name
            : `Meter ${this.$id}`
    }

    getChildren() {
        return Clamp.query().where('meterId', this.meterId).get()
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
