import { get } from 'lodash'
import { stripCountryFromRoute } from '~/config/i18n'

const widgets = ['gauge']

export const LAYOUT_OPTIONS_BY_COMMODITY = commodity => {
    const defaultLayoutOptions = {
        virtual: true,
        alert: {
            active: true,
            resolution: 1800,
        },
        initialUnit: null,
        selectedUnit: null,
        icon: '$building',
        tracking: true,
        menu: {
            active: true,
            icon: '$icon',
        },
        chart: {
            toolbarButtons: ['reset', 'lock', 'compare', 'peak', 'inspector'],
        },
        inspectorAggregate: {
            max: ['U_GENE', 'GVOL', 'GCAL'],
            min: ['U_GENE', 'GVOL', 'GCAL'],
            sum: ['U_GENE', 'GVOL', 'U_CO2E', 'C'],
            avg: ['U_GENE', 'GVOL', 'GCAL', 'C'],
        },
    }
    const r = {
        power: {
            route: {
                path: 'electricity',
            },
            virtual: true,
            tracking: true,
            initialUnit: 'kw',
            selectedUnit: null,
            scope: {
                short: 'ELEC',
                long: 'electricity',
            },
            alert: {
                active: true,
                resolution: 900,
            },
            selectedParameterKeys: ['P'],
            menu: {
                active: true,
                icon: '$cvelectricity',
            },
            dashboard: {
                active: true,
                icon: '$cvelectricity',
                text: 'Power',
                widgetIconColor: 'yellow darken-3',
                widgets,
            },
            treeview: {
                icon: '$building',
            },
            chart: {
                toolbarButtons: ['reset', 'triads', 'loadFactor', 'contracts', 'lock', 'compare', 'peak', 'rates', 'inspector', 'notes'],
            },
            params: ['E', 'U_CO2E', 'C', 'S', 'PF', 'Q'],
            inspectorAggregate: {
                sum: ['E', 'C', 'U_DURE', 'C_DURE', 'U_CO2E', 'RE', 'C_DUXCAP', 'U_DUXCAP'],
                max: ['S', 'P', 'Q'],
                min: ['PF'],
                avg: ['P', 'PF', 'S', 'Q'],
            },
            units: currency => {
                return [
                    {
                        id: 'kw',
                        param: 'P',
                        allowPreview: true,
                        instant: true,
                        symbols: {
                            base: ['W', 1],
                            kilo: ['kW', 1e3],
                            mega: ['MW', 1e6],
                            giga: ['GW', 1e9],
                            tera: ['TW', 1e12],
                        },
                        dataAggregation: 'max',
                        aggregates: ['avg', 'max', 'min'],
                        scope: 'electricity',
                    },
                    {
                        id: 'kwh',
                        param: 'E',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['Wh', 1],
                            kilo: ['kWh', 1e3],
                            mega: ['MWh', 1e6],
                            giga: ['GWh', 1e9],
                            tera: ['TWh', 1e12],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'electricity',
                    },
                    {
                        id: 'kva',
                        param: 'S',
                        allowPreview: true,
                        instant: true,
                        symbols: {
                            base: ['VA', 1],
                            kilo: ['kVA', 1e3],
                            // giga: ['GVA', 1e9],
                            // tera: ['TVA', 1e12],
                        },
                        dataAggregation: 'max',
                        aggregates: ['avg', 'max', 'min'],
                        scope: 'electricity',
                    },
                    {
                        id: 'kvah',
                        param: 'AE',
                        // symbol: 'kVAh',
                        // name: 'Apparent Energy',
                        allowPreview: true,
                        instant: false,
                        // _multiplier: 1,
                        symbols: {
                            base: ['VAh', 1],
                            kilo: ['kVAh', 1e3],
                            mega: ['MVAh', 1e6],
                            giga: ['GVAh', 1e9],
                            tera: ['TVAh', 1e12],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'electricity',
                    },
                    {
                        id: 'pf',
                        param: 'PF',
                        // symbol: 'PF',
                        // name: 'Power Factor',
                        allowPreview: true,
                        instant: true,
                        dataAggregation: 'avg',
                        aggregates: ['avg', 'max', 'min'],
                        scope: 'electricity',
                        disableMultipliers: true,
                    },
                    {
                        id: 'pf1',
                        param: 'PF1',
                        scope: 'electricity',
                        disableMultipliers: true,
                    },
                    {
                        id: 'pf2',
                        param: 'PF2',
                        scope: 'electricity',
                        disableMultipliers: true,
                    },
                    {
                        id: 'pf3',
                        param: 'PF3',
                        scope: 'electricity',
                        disableMultipliers: true,
                    },
                    {
                        id: 'i',
                        param: 'I',
                        scope: 'electricity',
                        staticMultiplier: 1,
                    },
                    {
                        id: 'i1',
                        param: 'I1',
                        scope: 'electricity',
                        staticMultiplier: 1,
                    },
                    {
                        id: 'i2',
                        param: 'I2',
                        scope: 'electricity',
                        staticMultiplier: 1,
                    },
                    {
                        id: 'i3',
                        param: 'I3',
                        scope: 'electricity',
                        staticMultiplier: 1,
                    },
                    {
                        id: 'kvar',
                        param: 'Q',
                        // symbol: 'kVAr',
                        // name: 'Reactive Power',
                        allowPreview: true,
                        instant: true,
                        // _multiplier: 1,
                        symbols: {
                            base: ['VAr', 1],
                            kilo: ['kVAr', 1e3],
                            mega: ['MVAr', 1e6],
                            giga: ['GVAr', 1e9],
                            tera: ['TVAr', 1e12],
                        },
                        dataAggregation: 'max',
                        aggregates: ['avg', 'max', 'min'],
                        scope: 'electricity',
                    },
                    {
                        id: 'kvarh',
                        param: 'RE',
                        // symbol: 'kVArh',
                        // name: 'Reactive Energy',
                        allowPreview: true,
                        instant: false,
                        // _multiplier: 1,
                        symbols: {
                            base: ['VArh', 1],
                            kilo: ['kVArh', 1e3],
                            mega: ['MVArh', 1e6],
                            giga: ['GVArh', 1e9],
                            tera: ['TVArh', 1e12],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'electricity',
                    },
                    {
                        id: 'co2e',
                        param: 'U_CO2E',
                        // symbol: 'kg',
                        // name: 'CO₂e',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['kg', 1],
                            kilo: ['t', 1e3],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'electricity',
                    },
                    {
                        id: 'cost',
                        param: 'C',
                        // symbol: currency.symbol,
                        // name: 'Cost',
                        allowPreview: false,
                        instant: false,
                        symbols: {
                            base: [currency.symbol, 1],
                            kilo: [`${currency.symbol}k`, 1e3],
                            mega: [`${currency.symbol}M`, 1e6],
                        },
                        disableMultipliers: true,
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'electricity',
                        unitBeforeValue: true,
                    },
                    {
                        id: 'loadFactor',
                        // symbol: 'LF',
                        name: 'Load Factor',
                        dataAggregation: 'avg',
                        scope: 'electricity',
                        hideFromTable: true,
                    },
                    {
                        id: 'triad',
                        // symbol: 'T',
                        name: 'Triads',
                        symbols: {
                            base: [currency.symbol, 1],
                            kilo: [`${currency.symbol}k`, 1e3],
                            mega: [`${currency.symbol}M`, 1e6],
                        },
                        dataAggregation: 'sum',
                        scope: 'electricity',
                    },
                ]
            },
            userState: {
                clamps: 'circuits',
                meters: 'meters',
            },
        },
        gas: {
            virtual: true,
            initialUnit: 'U_GENE',
            selectedUnit: null,
            tracking: true,
            treeview: {
                icon: '$building',
            },
            selectedParameterKeys: ['U_GENE'],
            menu: {
                active: true,
                icon: '$cvgas',
            },
            dashboard: {
                active: true,
                icon: '$cvgas',
                text: 'Gas',
                widgetIconColor: 'deep-purple accent-1',
                widgets: [],
            },
            chart: {
                toolbarButtons: ['reset', 'lock', 'compare', 'peak', 'inspector'],
            },
            alert: {
                active: true,
                resolution: 1800,
            },
            params: ['E', 'U_CO2E', 'C', 'GVOL', 'GCAL'],
            inspectorAggregate: defaultLayoutOptions.inspectorAggregate,
            units: _currency => {
                return [
                    {
                        id: 'gvol',
                        param: 'GVOL',
                        // symbol: 'm³',
                        // name: 'Volume',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['m³', 1],
                            deca: ['dam³', 1e3],
                            hecto: ['hm³', 1e6],
                            kilo: ['km³', 1e9],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'gas',
                        disableMultipliers: true,
                    },
                    {
                        id: 'ugene',
                        param: 'U_GENE',
                        // symbol: 'kWh',
                        // name: 'Energy',
                        allowPreview: true,
                        instant: false,
                        // _multiplier: 1,
                        symbols: {
                            base: ['Wh', 1],
                            kilo: ['kWh', 1e3],
                            mega: ['MWh', 1e6],
                            giga: ['GWh', 1e9],
                            tera: ['TWh', 1e12],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'gas',
                    },
                    {
                        id: 'cgene',
                        param: 'C_GENE',
                        // symbol: currency.symbol,
                        // name: 'Cost',
                        allowPreview: true,
                        instant: false,
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'gas',
                        originalParam: 'C',
                        disableMultipliers: true,
                        unitBeforeValue: true,
                    },
                    {
                        id: 'gcal',
                        param: 'GCAL',
                        // symbol: 'MJ/m3',
                        // name: 'Calorific Value',
                        allowPreview: true,
                        instant: true,
                        dataAggregation: 'avg',
                        aggregates: ['avg', 'max', 'min'],
                        scope: 'gas',
                        disableMultipliers: true,
                    },
                    {
                        id: 'uco2e',
                        param: 'U_CO2E',
                        // symbol: 'kg',
                        // name: 'CO₂e',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['g', 1],
                            kilo: ['kg', 1e3],
                            mega: ['t', 1e6],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'gas',
                    },
                ]
            },
            userState: {
                clamps: 'pipes',
                meters: 'gas-meters',
            },
        },
        water: {
            virtual: true,
            initialUnit: 'U_WVOL',
            selectedUnit: null,
            tracking: false,
            treeview: {
                icon: '$building',
            },
            selectedParameterKeys: ['U_WVOL'],
            menu: {
                active: true,
                icon: '$cvwater',
            },
            dashboard: {
                active: false,
                icon: '$cvwater',
                text: 'Water',
                widgetIconColor: 'deep-blue accent-1',
                widgets: [],
            },
            chart: {
                toolbarButtons: ['reset', 'lock', 'compare', 'peak', 'inspector'],
            },
            alert: {
                active: false,
                resolution: 1800,
            },
            params: ['U_WVOL', 'C_WVOL', 'C', 'WVOL', 'WCAL'],
            inspectorAggregate: defaultLayoutOptions.inspectorAggregate,
            units: _currency => {
                return [
                    {
                        id: 'wvol',
                        param: 'WVOL',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['m³', 1],
                            deca: ['dam³', 1e3],
                            hecto: ['hm³', 1e6],
                            kilo: ['km³', 1e9],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'water',
                        disableMultipliers: true,
                    },
                    {
                        id: 'uwvol',
                        param: 'U_WVOL',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['m³', 1],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'water',
                        disableMultipliers: true,
                    },
                    {
                        id: 'cwvol',
                        param: 'C_WVOL',
                        allowPreview: true,
                        instant: false,
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        scope: 'water',
                        disableMultipliers: true,
                        unitBeforeValue: true,
                    },
                    {
                        id: 'wcal',
                        param: 'WCAL',
                        allowPreview: true,
                        instant: true,
                        dataAggregation: 'avg',
                        aggregates: ['avg', 'max', 'min'],
                        scope: 'water',
                    },
                    {
                        id: 'csew',
                        param: 'C_SEW',
                        dataAggregation: 'sum',
                        scope: 'water',
                        allowPreview: true,
                        disableMultipliers: true,
                        unitBeforeValue: true,
                        aggregates: ['avg', 'max', 'min', 'sum'],
                    },
                    {
                        id: 'usew',
                        param: 'U_SEW',
                        scope: 'water',
                        allowPreview: true,
                        instant: false,
                        symbols: {
                            base: ['m³', 1],
                        },
                        dataAggregation: 'sum',
                        aggregates: ['avg', 'max', 'min', 'sum'],
                        disableMultipliers: true,
                    },
                ]
            },
            userState: {
                clamps: 'water-pipes',
                meters: 'water-meters',
            },
        },
    }

    if (!commodity) return r

    return r[commodity] || defaultLayoutOptions
}

export const knownCommodities = Object.keys(LAYOUT_OPTIONS_BY_COMMODITY(null))
export const commoditySwitchItems = knownCommodities.filter(c => {
    const { dashboard } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    return dashboard && dashboard.active
}).map(c => {
    const { dashboard } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    return {
        icon: dashboard.icon,
        text: dashboard.text,
        value: c,
        color: dashboard.widgetIconColor,
    }
})

export const DASHBOARD_BY_COMMODITY = commoditySwitchItems.map(c => c.value)

export const COMMODITY_PARAMS = knownCommodities.reduce((acc, c) => {
    const { params } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    if (!acc[c]) acc[c] = params
    return acc
}, {})

export const COMMODITY_UNITS = currency => knownCommodities.flatMap(c => {
    const { units } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    return units(currency) ?? []
})

export const COMMODITY_ICONS = Object.fromEntries(commoditySwitchItems.map(({ icon, color, value }) => ([value, { icon, color }])))

export const ALERTS_BY_COMMODITY = knownCommodities.filter(c => LAYOUT_OPTIONS_BY_COMMODITY(c)?.alert?.active)

export const VIRTUAL_BY_COMMODITY = knownCommodities.filter(c => LAYOUT_OPTIONS_BY_COMMODITY(c)?.virtual)

export const TRACKING_BY_COMMODITY = knownCommodities.filter(c => LAYOUT_OPTIONS_BY_COMMODITY(c)?.tracking)

export const WIDGET_BY_COMMODITY = ['all', ...knownCommodities].reduce((acc, c) => {
    const { dashboard } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    acc[c] = dashboard?.widgets || widgets
    return acc
}, {})

export const TREE_ITEMS_COMMODITY_STATE = knownCommodities.reduce((acc, c) => {
    acc[c] = { all: [], virtual: [] }
    return acc
}, {})

export const USER_STATE_BY_COMMODITY_MAPPING = knownCommodities.reduce((acc, c) => {
    const { userState } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    acc[c] = Object.entries(userState)
    return acc
}, {})

export const getScopeByCommodity = maybeCommodity => {
    if (!maybeCommodity || typeof maybeCommodity !== 'string') return

    const l = maybeCommodity.toLowerCase()
    const { scope } = LAYOUT_OPTIONS_BY_COMMODITY(l)

    return scope || { short: maybeCommodity, long: maybeCommodity }
}

export const getCommodityByScope = maybeScope => {
    if (!maybeScope || typeof maybeScope !== 'string') return
    const l = maybeScope.toLowerCase()
    const findCommodityByScope = Object.entries(LAYOUT_OPTIONS_BY_COMMODITY(null)).find(([k, obj]) => {
        const { scope } = obj
        return scope ? Object.values(scope).map(v => v.toLowerCase()).includes(l) : (k === l)
    })

    return findCommodityByScope && findCommodityByScope.length ? findCommodityByScope[0] : maybeScope
}

export const getResolutionByCommodity = (maybeCommodity, key) => {
    if ((!maybeCommodity && !key) || typeof maybeCommodity !== 'string') return

    const l = maybeCommodity.toLowerCase()
    const resolution = get(LAYOUT_OPTIONS_BY_COMMODITY(l), `${key}.resolution`)

    if (!resolution) {
        console.warn(`There is no resolution for ${l}`)
        return
    }

    return resolution
}

export const getCommodityByRoute = maybeRoute => {
    const routePath = stripCountryFromRoute(maybeRoute)
    if (!routePath) return

    const findCommodityByScope = Object.entries(LAYOUT_OPTIONS_BY_COMMODITY(null)).find(([k, obj]) => {
        const { route } = obj
        return route ? Object.values(route).includes(routePath) : (k === routePath)
    })

    if (!findCommodityByScope) {
        console.warn(`There is no commodity for this route ${maybeRoute}`)
        return
    }

    const [commodity, obj] = findCommodityByScope

    return {
        commodity: commodity.toLowerCase(),
        scope: (obj?.scope ? obj?.scope?.long : commodity).toLowerCase(),
    }
}

export const findCommodityAndOppositeState = searchValue => {
    const findEntity = Object.entries(USER_STATE_BY_COMMODITY_MAPPING).find(([c, m]) => m.find(e => e.includes(searchValue)))
    if (findEntity) {
        return [findEntity[0], findEntity[1].find(e => e.includes(searchValue))[0] ?? searchValue]
    }
    return [null, null]
}
