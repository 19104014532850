import { Model } from '@vuex-orm/core'
import { concat, find, uniqBy } from 'lodash'
import Site from './Site'
export default class CarbonSource extends Model {
    #children
    #parent

    static entity = 'carbon-sources'
    static apiConfig = {
        baseURL: '/net-zero-api',
        actions: {
            addAsset(asset) {
                return this.post('assets/asset', asset, { dataKey: null })
            },
            updateAsset(asset) {
                return this.put(`assets/asset/${asset.id}`, asset)
            },
            deleteAsset(asset) {
                return this.delete(`assets/asset/${asset.id}`, {
                    delete: asset.id,
                })
            },
            addOrUpdateAsset(asset) {
                if (asset.id) {
                    return this.updateAsset(asset)
                } else {
                    return this.addAsset(asset)
                }
            },
            deleteAssetImage(asset) {
                return this.delete(`assets/asset/${asset.id}/avatar`)
            },
        },
    }

    static fetchUri() {
        return 'assets/flat'
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            parentId: this.attr(null),
            // children: this.hasMany(CarbonSource, 'parentId', 'id'),
            // parent: this.belongsTo(CarbonSource, 'parentId', 'id'),
            icon: this.attr(null),
            // templateId: this.attr(null),
            // template: this.hasOne(CarbonSourceTemplate, 'id', 'templateId'),
            // templateValues: this.attr(null),
            actions: this.attr(null),
            inputGroups: this.attr(null),
            attributes: this.attr(null),
            records: this.number(0),
            tags: this.attr([]), // unique per item
            description: this.attr(null),
            img: this.attr(null),
            targetCount: this.attr(null),
        }
    }

    static beforeCreate(model) {
        // if(model.$store().$cookie.get('anon')){
        //     const sites = model.$store().state.entities.sites.data
        //     // console.log(JSON.stringify(sites))
        //     const site = find(sites, (({ realName }) => realName === model.name))
        //     // console.log(model.name, site)
        // }
        // console.log(model)
        if (model.parentId === 'at-vehicle') {
            model.icon = '$car'
            return
        }
        if (!model.parentId) {
            model.icon = '$folder'
            return
        }
        const iconsById = {
            'c-stationary-combustion': '$fire',
            'c-mobile-combustion': '$gasPump',
            'at-vehicle': '$car',
            'at-power': '$bolt',
            'c-fugitive-emissions': '$heat',
            'c-process-emissions': '$gear',
            'c-electricity': '$bolt',
            'c-indirect-emissions': '',
            'c-upstream-activities': '',
            'c-waste-generated': '$dumpster',
            'c-purchased-goods-and-services': '$cubes',
            'c-transportation-and-distribution': '$truckContainer',
            'c-fuel-and-energy-related-activities': '$gasPump',
            'c-capital-goods': '',
            'c-downstream-activities': '',
            'c-franchises': '$cashRegister',
            'c-leased-assets': '$cashRegister',
        }
        model.icon = iconsById[model.id] || ''

        const tagsById = {
            'at-gaseous': ['Gas heater'],
            'at-liquid': [],
            'at-biofuel': [],
            'at-biomass': [],
            'at-biogas': [],
            'at-solid': [],
            'at-refrigerant': [],
            'at-vehicle': ['Car'],
            'at-vehicle-van': ['Van'],
            'at-vehicle-hgv': ['Truck'],
            'at-power': [],
            'at-heat-steam': [],
        }
        model.tags = tagsById[model.id] || []
    }

    /**
     * Get css class for treeview
     * - we use this css class to determine the color associated with each element
     * @return  {String} class
     */
    get cssClass() {
        if (!this.parentId) { // only for top level elements
            return this.scopeNumber ? `scope--${this.scopeNumber}` : 'out-of-scope'
        }
    }

    get children() {
        this.#children = this.#children || CarbonSource.query().where('parentId', this.id).get()
        return this.#children
    }

    get parent() {
        this.#parent = this.#parent || CarbonSource.find(this.parentId)
        return this.#parent
    }

    get siteId() {
        return this.attributes?.siteId
    }

    get inputs() {
        if (!this.inputGroups || !this.inputGroups.length) {
            return null
        }

        return this.inputGroups.find(({ id }) => id === 'default')?.inputs || this.inputGroups[0]?.inputs
    }

    get siblings() {
        return this.parent ? this.parent.children.filter(({ id }) => id !== this.id) : this.$query().where('parentId', null).get()
    }

    get thumbnail() {
        if (this.img) {
            return {
                type: 'img',
                src: this.img,
            }
        } else {
            return {
                type: 'v-icon',
                src: this.ancestors.filter(({ icon }) => icon)[0].icon,
            }
        }
    }

    get descendants() {
        let children = this.children
        this.children.forEach(child => {
            children = concat(children, child.descendants)
        })
        return children
    }

    getDescendantIds() {
        return this.descendants.map(d => {
            return d.id
        })
    }

    get category() {
        const categories = this.$store().getters['entities/carbon-sources/categories']
        let category = categories.find(({ id }) => id === this.parentId)
        if (!category) {
            category = categories.find(({ id }) => id === this.parent.parentId)
        }
        if (!category) {
            const categoryId = this.parent?.parentId || this.parentId
            category = CarbonSource.find(categoryId)
        }
        return category
    }

    get ancestors() {
        let parent = this.parent // || this.$store().$db().model('carbon-sources').query().withAllRecursive().whereId(this.parentId).first()
        if (!parent && this.parentId?.includes('c-scope-')) {
            parent = this.$store().getters['entities/carbon-sources/scopes'].find(({ id }) => id === this.parentId)
        }
        return parent ? concat([parent], parent.ancestors) : []
    }

    get canEditData() {
        return this.actions.includes('editData')
    }

    get canAddData() {
        return this.actions.includes('addData')
    }

    get scope() {
        if (!this.parentId) return this
        return this.ancestors.find(({ parentId }) => !parentId)
    }

    get scopeNumber() {
        return Number(this.scope?.name?.slice(-1) || 1)
    }

    get safeName() {
        if (this.$store().$cookie.get('anon')) {
            const sites = this.$store().state.entities.sites.data
            const site = find(sites, ({ realName }) => realName === this.name)
            if (site) {
                return site.name
            }
        } else if (this.$store().$config.envName === 'demo' || this.$store().$config.envName === 'local') {
            if (this.parentId === 'at-power' || this.parentId === 'at-gaseous') {
                // console.log(this.id, this.name, this.siteId, this.parentId)
                const sites = this.$store().state.entities.sites.data
                const site = find(sites, ({ siteId }) => `${siteId}` === `${this.siteId}`)
                // console.log(this.id, this.name, this.siteId, site)
                if (site) {
                    return site.name
                }
            }
        }
        return this.name
    }

    async uploadData(data) {
        const res = await this.$store().$axios.$post(`net-zero-api/assets/asset/${this.id}/data`, data)
        await this.$update({
            records: this.records + data.data.length,
        })
        return res
    }

    async getRawData(page = 1, limit = 50) {
        try {
            return await this.$store().$axios.$get(`net-zero-api/assets/asset/${this.id}/data`, {
                params: {
                    page,
                    limit,
                    withEmissions: true,
                    inputs: 'raw',
                },
            })
        } catch (e) {
            return false
        }
    }

    async getAssetImage() {
        try {
            return await this.$store().$axios.$get(`net-zero-api/assets/asset/${this.$id}/avatar`,
                { params: { stream: false } })
                .then(({ data }) => data)
        } catch (e) {
            return null
        }
    }

    get restrictedInputs() {
        if (!this.siteId) {
            console.warn('This function can be invoked only for a physical asset')
            return []
        }

        const inputGroups = this.parent.inputGroups
        if (!inputGroups || !inputGroups.length) {
            return null
        }

        const site = Site.find(this.siteId)
        const assetAttributes = {
            ...this.attributes,
            country: site.countryISO,
        }

        const filteredGroups = inputGroups.filter(({ restrictions }) => !restrictions || restrictions.every(({ key, options }) => {
            const lookup = assetAttributes[key] ? assetAttributes[key].toLowerCase() : null
            return lookup && (options || []).some(o => o.toString().toLowerCase() === lookup)
        }))

        const adjustedArray = uniqBy(filteredGroups.flatMap(({ id, inputs, preferred }) => inputs.map(i => ({ ...i, inputKey: i.key, group: id, preferred }))), 'key')
            .map(i => ({ ...i, group: i.key === 'timestamp' ? 'uniq' : i.group }))

        return adjustedArray
    }
}
