/* Models */
import { Model } from '@vuex-orm/core'
import { get, groupBy } from 'lodash'
import Meter from './Meter'
import Preset from './Preset'

export default class Clamp extends Model {
    static entity = 'clamps'

    static isParamsDynamic = true

    static fields() {
        return {
            id: this.attr(null),
            uId: this.attr(null),
            parentId: this.attr(null),
            meterId: this.attr(null),
            icon: this.attr('$bolt'),
            name: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            color: this.attr(null),
            backupColor: this.attr(null),
            editable: this.boolean(true),
            virtual: this.boolean(false),
            preset: this.belongsTo(Preset, 'presetId'),
            isMainIncomer: this.boolean(false),
            startTime: this.attr(null),
            endTime: this.attr(null),
            childrenCount: this.attr(0),
            sampleRate: this.attr(null),
            aggregates: this.attr({}),
            totalKwData: this.number(0),
            createdAt: this.attr(null),
            updatedAt: this.attr(null),
            manufacturer: this.attr(null),
            hasData: this.boolean(true),
            commodity: this.attr(null),
            fuel: this.attr(null),
        }
    }

    static virtualId(presetId) {
        return 'V' + presetId
    }

    static apiConfig = {
        url: 'clamps',
        dataTransformer: ({ data }) => {
            if (Array.isArray(data?.data)) {
                const groupedByParentId = groupBy(data.data, 'parentId')
                // TODO: update models in the database to come with the childrenCount prop from the API so we don't have to do this every time
                return data.data.map(clamp => {
                    let childrenCount = 0
                    if (groupedByParentId[clamp.id]) {
                        childrenCount = groupedByParentId[clamp.id].length
                    }
                    clamp.childrenCount = childrenCount
                    return clamp
                })
            }
            return data
        },
    }

    get entity() {
        return Clamp.entity
    }

    get site() {
        return get(this, 'relationships.site', null)
    }

    get meter() {
        return Meter.find(this.meterId)
    }

    get siteId() {
        return get(this, 'site.siteId', null)
    }

    get clampId() {
        return this.id
    }

    get siteName() {
        return get(this, 'site.name')
    }

    /**
    * Get pipe parent
    */
    get parent() {
        if (this.virtual) {
            return Preset.find(this.presetId)
        }
        if (this.parentId) {
            return Clamp.find(this.parentId)
        }
        return this.getMeter()
    }

    /**
    * Get pipe children
    */
    get children() {
        return this.getChildren()
    }

    get siblings() {
        if (this.virtual) {
            return this.parent.clampsChildren.filter(c => c.id !== this.id)
        } else {
            return this.parent.children.filter(c => c.id !== this.id && c.parentId === this.parentId)
        }
    }

    /**
    * Get model relationships
    *
    * @return {Object}
    */
    get relationships() {
        // meter
        const meter = this.getMeter()
        // site
        const site = meter && meter.parent
        return {
            site,
            meter,
            parent: this.parent,
        }
    }

    get isNHH() {
        return this.manufacturer === 10
    }

    getName({ friendly } = {}) {
        let name
        if (this.isMainIncomer || this.virtual) {
            name = this.parent.getName({ friendly })
        } else if (friendly && this.friendlyName) {
            name = this.friendlyName
        } else {
            name = this.name
        }

        if (!this.hasData && !(this.isMainIncomer && (this.parent.children.length && this.parent.children.some(c => !c.isMainIncomer && c.hasData)))) {
            name = `${name} (No data)`
        }

        return name
    }

    getSite() {
        return this.getMeter()?.parent
    }

    getMeter() {
        return this.meter || Meter.find(this.meterId)
    }

    getChildren() {
        if (this.childrenCount === 0) return []
        return Clamp.query()
            .where('parentId', this.id)
            .where('commodity', this.commodity)
            .orderBy('totalKwData', 'desc')
            .get()
    }

    getUnitData(unit, resolution) {
        if (!unit) {
            console.warn('Unit is required')
            return
        }
        if (!this.id) {
            console.warn('ID is missing for some alien reason.')
            return
        }

        return this.$store().$cache.getUnitData(unit, this, this.commodity, resolution)
    }

    supportsUnit(unit) {
        const manufacturers = Array.isArray(this.manufacturer) ? this.manufacturer : [this.manufacturer]
        return unit.manufacturers.some(m => manufacturers.includes(m))
    }

    isNonHalfHourly() {
        return this.isMainIncomer && this.manufacturer === 10
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            entity: this.entity,
            id: this.id,
            children: this.children,
        }
    }
}
