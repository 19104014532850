// import download from 'downloadjs'
import moment from '~/composables/useMoment'
import { fileDownload } from '~/utils/tools'

export default class FileService {
    constructor({ $axios, $config, $store }) {
        this.$axios = $axios
        this.$config = $config
        this.$store = $store
    }

    /**
     * Fetch the urls for the report files to download
     * @param  {Object} payload
     * @return {Array}
     */
    fetchReportUrls(payload) {
        // TODO
        return []
    }

    /**
     * Get the urls for the PDF files to download
     * @param  {Object} payload
     * @return {Array}
     */
    downloadReport(payload) {
        // TODO
    }

    /**
     * Download csv report file
     * @param  {Object} params
     * @return {Boolean}
     */
    async downloadCsvReport(params) {
        const uri = `${this.$config.axios.reportsServiceURL}/v3/reports/xlsx`
        try {
            const res = await this.$axios.$get(uri, { params, responseType: 'blob' })
            fileDownload(
                res,
                `${moment().format('YYYYMMDD_HHmmss')}_readings_report.xlsx`,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            )
        } catch (err) {
            const isBlobError = err.request.responseType === 'blob' &&
                err.response.data instanceof Blob &&
                err.response.data.type &&
                err.response.data.type.toLowerCase().includes('json')
            const { message } = isBlobError ? JSON.parse(await err.response.data.text()) : err.response.data
            window.$nuxt.$toast.error(message || window.$nuxt.$t('form.responses.download_failed'))
            console.warn('Error while fetching download PDF link for inspector data. ', err)
        }
    }

    /**
     * Download pdf report file
     *
     * @param slug
     * @param  {Object} params
     * @param name
     * @return {Boolean}
     */
    async downloadPdfReport(slug, params = {}, name = 'pdf_report', method = 'GET') {
        let success = false
        if (!params.format) {
            params.format = 'pdf'
        }
        try {
            const res = await this.$axios({
                method,
                url: `/reports/${slug}`,
                params,
                responseType: 'blob',
            }).then(d => d.data)
            fileDownload(res, `${moment().format('YYYYMMDD_HHmmss')}_${name}.pdf`, 'application/pdf')
            success = true
            window.$nuxt.$toast.success('The full report has been successfully downloaded')
        } catch (err) {
            console.warn('Error while downloading the pdf report. ', err)
        }
        this.$store.dispatch('global/setDownloadLoader', false)
        if (!success) {
            window.$nuxt.$toast.error(window.$nuxt.$t('form.responses.download_failed'))
        }
        return success
    }
}
