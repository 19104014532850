import pluralize from 'pluralize'
import { capitalize, each, filter, groupBy, last, memoize, orderBy, uniq, uniqBy, upperFirst, values } from 'lodash'
import colors from 'vuetify/lib/util/colors'
import { eliberate } from './state'
import { ALERTS_BY_COMMODITY, DASHBOARD_BY_COMMODITY, findCommodityAndOppositeState, getCommodityByRoute, getCommodityByScope, knownCommodities, LAYOUT_OPTIONS_BY_COMMODITY } from './commodities'
import moment from '~/composables/useMoment'
// ORM
import Preset from '~/orm/models/Preset'
import { childrenHaveData } from '~/components/treeview/utils'
import { stripCountryFromRoute } from '~/config/i18n'

const COMMODITIES = DASHBOARD_BY_COMMODITY.map(c => {
    const { customPath } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    return customPath ?? c
})

export const COMMODITY_TYPES = knownCommodities.reduce((acc, c) => {
    const { customPath } = LAYOUT_OPTIONS_BY_COMMODITY(c)
    const key = customPath ?? c
    if (!acc[key]) acc[key] = key
    return acc
}, {})

export const ENTITY_NAMES = {
    Clamp: 'clamps',
    Meter: 'meters',
    Preset: 'presets',
    Site: 'sites',
    Treeview: 'treeviews',
}
const END_ENTITIES = [
    ENTITY_NAMES.Clamp,
    ENTITY_NAMES.Meter,
    ENTITY_NAMES.Preset,
]

export const EUID_SEPARATOR = '--'
export const EUIDS_SEPARATOR = '__'
export const EUID_COMMODITY_SEPARATOR = ':'

export const MANUFACTURER_CODES = {
    DUMMY: 5,
}

export const CIRCUIT_SORT_OPTIONS = {
    CONSUMPTION: 'consumption',
    NAME: 'name',
    // LOAD_FACTOR: 'load_factor',
    // AVERAGE_POWER: 'average_power',
}

/**
 * Page Entities
 * in order to function properly, pages needs some entities as described here
 * make sure no entity is repeated twice for the same page.
 * It's also preferable to add fundamental entities (entities that are dependencies for other entities, like units) at the beginning of the array.
 */
export const PAGE_ENTITIES = (path, $config, route) => {
    const r = {
        '/': ['units', 'parameters-presets', 'customers', 'sites', 'meters', 'clamps', 'presets'],
        '/alerts': ['units', 'parameters-presets', 'customers', 'sites', 'meters', 'presets', 'contracts', 'clamps'],
        '/carbon': ['units', 'parameters-presets', 'customers', 'sites', 'meters', 'carbon-sources'],
        '/events': ['units', 'parameters-presets', 'customers', 'presets', 'sites', 'meters', 'carbon-sources', 'treeviews', 'clamps'],
        '/jobs': ['units', 'parameters-presets', 'customers', 'sites', 'meters', 'presets', 'contracts'],
        '/reports': ['units', 'parameters-presets', 'sites', 'meters', 'presets', 'reports'],
        '/league-table': ['units', 'parameters-presets'],
        '/flows': ['units', 'parameters-presets', 'sites', 'meters', 'clamps'],
        '/:commodity': ['units', 'customers', 'sites', 'parameters-presets', 'meters', 'clamps', 'contracts', 'presets', 'user_annotations'],
    }

    const cleanPath = '/' + (stripCountryFromRoute(path) || '')
    let entities = r[cleanPath]
    switch (cleanPath) {
        case '/':
            entities = entities.concat($config.features.alertsEnabled ? ['alerts'] : [])
            break
        case '/:commodity':
            entities = $config.features.alertsEnabled && ALERTS_BY_COMMODITY.includes(getCommodityByRoute(route.path).commodity)
                ? entities.concat(['alerts'])
                : entities
            break
    }

    return uniq(entities)
}

/**
 * Tree builder cb
 * used to build the treeview items
 * from entities class instances
 *
 * @param {Object} param0
 */
export function buildTreeviewItems({ items = [], store, parent, scope, virtualParent } = {}) {
    if (!Array.isArray(items)) return

    // order by totalKwData
    if (items.length > 0 && parent && ['meters', 'presets', 'clamps'].includes(parent.entity)) {
        items = orderBy(items, ['totalKwData'], ['desc'])
    }

    return items.map(item => {
        const id = item.id
        const $id = item.$id
        const entity = item.entity
        let euid = createEUID(['sites', 'meters', 'clamps'].includes(entity) ? { id, $id, entity } : item)
        const name = item.getName()
        const friendlyName = item.getName({ friendly: true })
        const access = item.access
        const isHidden = item.isHidden
        let mpanName
        let disabled = entity === 'clamps' && !item.hasData && item.childrenCount === 0
        let activatable = ['presets', 'meters', 'clamps'].includes(entity)
        let title
        let color = item.color
        let itemChildren
        if (!virtualParent && scope && entity === 'sites') {
            itemChildren = item.getChildren(scope)
        } else {
            itemChildren = item.children
        }
        const customIcons = []
        const customIconTooltips = []
        let onSelect
        let mainIncomer

        if (entity === 'sites') {
            itemChildren = itemChildren.sort((a, b) => b.mainIncomer?.totalKwData - a.mainIncomer?.totalKwData)
            if (virtualParent) {
                activatable = true
            }
        }
        if (['meters'].includes(entity)) {
            mpanName = item.getName({ showMpan: true })
            itemChildren = item.children.filter(c => !c.isMainIncomer)
            mainIncomer = (item.relationships.clamps).find(c => c.isMainIncomer)
            if (mainIncomer) {
                color = mainIncomer.color
            } else {
                activatable = false
            }
        }

        if (entity === 'presets') {
            itemChildren = item.children.filter(c => !c.virtual)
            const virtualMainIncomer = item.children.find(c => c.virtual)
            if (!itemChildren.length) {
                activatable = false
                disabled = true
            }
            color = virtualMainIncomer.color
        }

        if (entity === 'clamps' && !item.hasData) {
            activatable = false
        }

        // For children of virtual meters
        if (parent && parent.entity === 'presets') {
            /**
             * For below euid, we need to keep the virtual circuitsIds unique
             * since we can have same circuit belonging to multiple virtual meters
             */
            euid = createTreeEUID([parent, { id, $id, entity }])
            // add title to children circuits of virtual meters (CLAL-513)
            if (item.parent) title = `${item.parent.getName()} ${item.parent.parent ? item.parent.parent.getName() : ''}`
        }

        if (item.manufacturer === 5 || mainIncomer?.manufacturer === 5) {
            customIcons.push('$c')
            customIconTooltips.push('This circuit is automatically generated from the consolidation of its sub-circuits')
        }

        const children = buildTreeviewItems({ items: itemChildren, parent: item, store, scope, virtualParent: entity === 'presets' })
        return {
            $id,
            id,
            euid,
            name,
            title,
            color,
            entity,
            children,
            disabled,
            activatable,
            mpanName,
            friendlyName,
            access,
            isHidden,
            customIcons,
            customIconTooltips,
            onSelect,
        }
    })
}

export function buildTreeviewBySite(sites = []) {
    return sites.map(site => {
        return {
            entity: site.entity,
            euid: createEUID({ id: site.id, entity: site.entity, $id: site.$id }),
            name: site.name,
            children: COMMODITIES.map(commodity => {
                return {
                    euid: createEUID({ ...site, entity: site.entity, commodity }),
                    name: upperFirst(commodity),
                    children: site.meters.map(meter => {
                        return {
                            activatable: true,
                            entity: meter.entity,
                            euid: createEUID({ id: meter.id, $id: meter.$id, entity: meter.entity }),
                            name: meter.getName(),
                            friendlyName: meter.getName({ friendly: true }),
                            mpanName: meter.getName({ showMpan: true }),
                        }
                    }),
                }
            }),
        }
    })
}

export function buildTreeviewByCommodity(sites = []) {
    return COMMODITIES.map(commodity => {
        return {
            euid: commodity,
            name: upperFirst(commodity),
            children: sites.map(site => {
                return {
                    entity: site.entity,
                    euid: createEUID({ ...site, entity: site.entity, commodity }),
                    name: site.name,
                    children: site.meters.map(meter => {
                        return {
                            activatable: true,
                            entity: meter.entity,
                            euid: createEUID({ id: meter.id, $id: meter.$id, entity: meter.entity }),
                            name: meter.getName(),
                            friendlyName: meter.getName({ friendly: true }),
                            mpanName: meter.getName({ showMpan: true }),
                        }
                    }),
                }
            }),
        }
    })
}

function hhCircuitResolutionCheck(store) {
    return async() => {
        if (store && store.state.period.resolution < 30 * 60) {
            const r = await store._vm.$confirm('This is going to switch the current resolution to 30 minutes, you want to continue?', {
                title: 'Please confirm', color: 'warning', icon: 'fas fa-circle-info',
            })
            if (r) {
                this.$PeriodService.updatePeriodWithDebounce({ resolution: 30 * 60 }, p => store.dispatch('period/update', p))
            }
            return r
        } else {
            return true
        }
    }
}

const cachedEntities = memoize((entity, store) => {
    const entities = store.$db().model(entity).all()
    const cache = {}
    for (const entity of entities) {
        cache[entity.id] = entity.$toJson()
    }
    return cache
})
/**
 * Tree builder cb
 * used to build the treeview items
 * from entities class instances
 *
 * @param {Object} param0
 */
export function buildTree({ items = [], parent, store, virtualParent } = {}) {
    if (!Array.isArray(items)) return

    // order by totalKwData
    if (items.length > 0 && parent && ['meters', 'clamps', 'presets'].includes(parent.entity)) {
        items = orderBy(items, ['totalKwData'], ['desc'])
    }

    // cache entities once for future use
    // doing this here and not in the model class cos here i'm sure all entities are loaded
    const cachedSites = cachedEntities('sites', store)
    const cachedMeters = cachedEntities('meters', store)

    return items.map(item => {
        const $id = item.$id
        const id = item.id
        const entity = item.entity
        let euid = createEUID(['sites', 'meters', 'clamps'].includes(entity) ? { $id, id, entity } : item)
        let name = item.getName()
        let friendlyName = item.getName({ friendly: true })
        let mpanName
        let itemChildren = item.children
        let disabled = entity === 'clamps' && !item.hasData && (!item.childrenCount || !childrenHaveData(itemChildren))
        let activatable = ['presets', 'meters', 'clamps'].includes(entity)
        let title
        let color = item.color
        const customIcons = []
        const customIconTooltips = []
        let onSelect
        let onActivate
        let mainIncomer
        let textLabel
        let customBadge
        let customBadgeToolTip
        let toggleChildrenOnClick = false
        let sites

        // use memoized sites
        const site = (() => {
            if (entity === 'clamps') {
                const meter = cachedMeters[item.meterId]
                return meter ? cachedSites[meter.siteId] : undefined
            } else if (entity === 'meters') {
                return cachedSites[item.siteId]
            } else if (entity === 'sites') {
                return item
            }
        })()

        if (entity === 'sites') {
            itemChildren = itemChildren.sort((a, b) => b.mainIncomer?.totalKwData - a.mainIncomer?.totalKwData)
            if (virtualParent) {
                activatable = true
            }
        }
        if (entity === 'meters') {
            mpanName = item.getName({ showMpan: true })
            itemChildren = item.children.filter(c => !c.isMainIncomer)
            mainIncomer = item.relationships.clamps.find(c => c.isMainIncomer)
            if (mainIncomer) {
                color = mainIncomer.color
                name = mainIncomer.getName()
                friendlyName = mainIncomer.getName({ friendly: true })
                if (!mainIncomer.hasData) {
                    if (childrenHaveData(itemChildren)) {
                        toggleChildrenOnClick = true
                    } else {
                        activatable = false
                        disabled = true
                    }
                }
            } else if (childrenHaveData(itemChildren)) {
                toggleChildrenOnClick = true
            } else {
                name = `${name} (No data)`
                mpanName = `${mpanName} (No data)`
                activatable = false
                disabled = true
            }

            if (item.connection === 2) {
                customIcons.push('$e')
                customIconTooltips.push('Export meter')
            } else if (item.connection === 3) {
                customIcons.push('$g')
                customIconTooltips.push('Off-grid meter')
            }
        }

        if (item.fuel || mainIncomer?.fuel) {
            customIcons.push('$g')
            customIconTooltips.push(`${capitalize(item?.fuel || mainIncomer.fuel)} energy generation`)
        }

        if (item.manufacturer === 5 || mainIncomer?.manufacturer === 5) {
            customIcons.push('$c')
            customIconTooltips.push('This clamp is automatically generated from the consolidation of its sub-clamp')
        }

        if (item.manufacturer === 2 || mainIncomer?.manufacturer === 2) {
            customIcons.push('$n')
            customIconTooltips.push('This clamp\'s data is imported from the grid and doesn\'t support the default 15 minutes resolution')
            onSelect = hhCircuitResolutionCheck(store)
            onActivate = hhCircuitResolutionCheck(store)
        }

        if (item.manufacturer === 10 || mainIncomer?.manufacturer === 10) {
            customBadge = `NHH${item.liveContract ? ` - ${item.liveContract.unit_rates.length} Rate` : ''}`
            customBadgeToolTip = 'This meter\'s data is imported from non-half hourly readings.<br />This circuit\'s data is imported from the grid and doesn\'t support the default 15 minutes resolution'
        }

        if (entity === 'presets') {
            itemChildren = item.children.filter(c => !c.virtual && !(c.entity === 'sites' && !c.getChildren(getCommodityByScope(item.scope)).length))
            if (!itemChildren.length) {
                activatable = false
                disabled = true
            }
            const virtualMainIncomer = item.children.find(c => c.virtual)
            color = virtualMainIncomer.color
        }

        if (entity === 'clamps' && !item.hasData) {
            activatable = false
        }

        // For children of virtual meters
        if (parent && parent.entity === 'presets') {
            /**
             * For below euid, we need to keep the virtual circuitsIds unique
             * since we can have same circuit belonging to multiple virtual meters
             */
            euid = createTreeEUID([parent, { id, $id, entity }])
            // add title to children circuits of virtual meters (CLAL-513)
            if (item.parent) title = `${item.parent.getName()} ${item.parent.parent ? item.parent.parent.getName() : ''}`
        }

        const children = buildTree({ items: itemChildren, parent: parent?.entity === 'presets' ? parent : item, store, virtualParent: entity === 'presets' })

        if (entity === 'presets') {
            sites = uniqBy(children.map(({ site }) => site), 'id')
        }

        return {
            $id,
            id,
            euid,
            name,
            title,
            color,
            entity,
            children,
            disabled,
            activatable,
            mpanName,
            friendlyName,
            customIcons,
            customIconTooltips,
            onSelect,
            onActivate,
            textLabel,
            access: item.access,
            isHidden: item.isHidden,
            totalKwData: item.totalKwData,
            customBadge,
            customBadgeToolTip,
            toggleChildrenOnClick,
            site,
            sites,
        }
    })
}

export function simpleTree(items = [], commodity = null) {
    if (!Array.isArray(items)) return

    return items.map(item => {
        const id = item.id
        const $id = item.$id
        const icon = item.icon
        const entity = item.entity
        const name = item.getName()
        const friendlyName = item.getName({ friendly: true })
        let color = item.color
        const disabled = entity === 'clamps' && !item.hasData
        let itemChildren = item.getChildren(commodity)
        let mpanName
        if (entity === 'meters') {
            mpanName = item.getName({ showMpan: true })
            itemChildren = item.getChildren(commodity).filter(c => !c.isMainIncomer && !c.parentId)
            const mainIncomer = (item.relationships.clamps).find(c => c.isMainIncomer)
            if (mainIncomer) {
                color = mainIncomer.color
            }
        }

        const children = simpleTree(itemChildren, commodity)
        return {
            id,
            $id,
            icon,
            name,
            color,
            entity,
            children,
            disabled,
            mpanName,
            friendlyName,
            commodity: item.commodity,
        }
    })
}

export function presetTree({ sites = [], preset } = {}) {
    const items = []
    const { presetId, siteIds, meterIds, circuitIds } = preset

    for (const site of sites) {
        const siteChildren = []

        for (const meter of site.children) {
            const meterChildren = []
            let hasMainIncomer = false
            for (const circuit of meter.children) {
                if (
                    !(
                        siteIds.includes(site.siteId) ||
                        meterIds.includes(circuit.meterId) ||
                        circuitIds.includes(circuit.id)
                    )
                ) {
                    continue
                }
                if (!circuitIds.includes(circuit.id)) continue

                if (circuit.isMainIncomer) {
                    hasMainIncomer = true
                } else {
                    meterChildren.push({
                        // circuit info
                        presetId,
                        id: circuit.id,
                        icon: circuit.icon,
                        entity: circuit.entity,
                        name: circuit.getName(),
                        friendlyName: circuit.getName({ friendly: true }),
                    })
                }
            }
            if (hasMainIncomer || meterChildren.length || meterIds.includes(meter.id)) {
                siteChildren.push({
                    // meter info
                    presetId,
                    id: meter.meterId,
                    icon: meter.icon,
                    entity: meter.entity,
                    name: meter.getName(),
                    mpanName: meter.getName({ showMpan: true }),
                    friendlyName: meter.getName({ friendly: true }),
                    children: meterChildren,
                })
            }
        }

        if (siteChildren.length || siteIds.includes(site.id)) {
            items.push({
                // site info
                presetId,
                id: site.siteId,
                icon: site.icon,
                entity: site.entity,
                name: site.getName(),
                friendlyName: site.getName({ friendly: true }),
                children: siteChildren,
            })
        }
    }

    return items
}

export function presetsTree({ sites = [], presets = [] } = {}, vm) {
    const triggerTree = async preset => {
        const { commodity } = preset
        vm.$cache.unloadEntityData('clamps', `V${preset.id}`)
        // setup virtual clamp
        await vm.$store.dispatch('entities/clamps/setupVirtual', { forced: true, commodity })
        // reset virtual tree items
        await vm.$store.dispatch('clamp/setVirtualTreeItems', { forced: true, commodity })
    }
    return presets.map(preset => {
        const extraActions = []
        if (!preset.isHidden) {
            extraActions.push({
                icon: '$eyeSlash',
                fn: async e => {
                    try {
                        const { response: { data: presetData } } = await Preset.api().hide(e)
                        await triggerTree(presetData)
                        vm.$toast.success(vm.$t('api.response.virtual_meter_hide_success'))
                    } catch (e) {
                        vm.$toast.error(vm.$t('form.responses.delete_success'))
                    }
                },
                description: vm.$t('actions.hide'),
                color: colors.lime.base,
            })
        } else {
            extraActions.push({
                icon: '$eye',
                fn: async e => {
                    try {
                        const { response: { data: presetData } } = await Preset.api().unhide(e)
                        await triggerTree(presetData)
                        vm.$toast.success(vm.$t('api.response.virtual_meter_unhide_success'))
                    } catch (e) {
                        vm.$toast.error(vm.$tUcf('api.response.entity_save_error', { entity: 'Virtual meter' }))
                    }
                },
                description: vm.$t('actions.unhide'),
                color: colors.lime.base,
            })
        }

        if (preset.isEditable) {
            extraActions.push({
                icon: '$pencil',
                fn: e => vm.handleDrop({ entity: 'presets', id: e.id }),
                description: vm.$t('actions.edit'),
                color: colors.amber.base,
            })
            extraActions.push({
                icon: '$delete',
                fn: e => vm.confirmPresetDelete(e),
                description: vm.$t('actions.delete'),
                color: colors.deepOrange.base,
            })
        }

        extraActions.push({
            icon: '$clone',
            fn: e => vm.clone(e),
            description: vm.$t('actions.clone'),
            color: colors.cyan.base,
        })

        return {
            id: preset.id,
            icon: preset.icon,
            entity: preset.entity,
            name: preset.getName(),
            children: presetTree({ sites, preset }),
            notDraggable: !preset.isEditable,
            extraIcons: [
                ...(preset.access === Preset.ACCESS_TYPES.PUBLIC ? [{ icon: '$userGroup', description: vm.$t(`access_control.${Preset.ACCESS_TYPES.PUBLIC}.title`) }] : []),
                ...(preset.access === Preset.ACCESS_TYPES.READ_ONLY
                    ? [
                        { icon: '$userGroup', description: vm.$t(`access_control.${Preset.ACCESS_TYPES.READ_ONLY}.title`) },
                        { icon: '$lock' },
                    ]
                    : []),
                ...(preset.access === Preset.ACCESS_TYPES.PRIVATE ? [{ icon: '$lock', description: vm.$t(`access_control.${Preset.ACCESS_TYPES.PRIVATE}.title`) }] : []),
            ],
            extraActions,
        }
    })
}

/**
 * Get ID from entity model
 *
 * @param {Object} entity
 * @returns {Number|String} id
 */
export function idFromEntity(entity = {}) {
    return entity.id || entity.$id
}

/**
 * Get IDs from entities array
 *
 * @param {Array} entities
 * @returns {Array} ids
 */
export function getIds(entities = []) {
    return entities.map(idFromEntity)
}

/**
 * Get IDs from entities array
 *
 * @param {Array} entities
 * @returns {Array} ids
 */
export function getEuids(entities = []) {
    return entities.map(createEUID)
}

/**
 * Get API name of the entity
 *
 * @param {String} entity
 * @returns {String} apiEntity
 */
export function apiEntityName(entity) {
    if (['clamps'].includes(entity)) {
        return 'clamps'
    }
    if (['meters'].includes(entity)) {
        return 'meters'
    }

    return entity
}

/**
 * Get ID key from entity model
 *
 * @param {Object} entity
 * @param {Boolean} plural
 * @returns {Number|String} id
 */
export function idKey(entity) {
    const key = pluralize.singular(apiEntityName(entity))
    return `${key}Ids`
}

/**
 * Create unique ID for an entity
 *
 * @params {Object} { entity, id }
 * @returns {String} uid
 */
export function createEUID({ entity, id, $id, commodity }) {
    if (!id) id = $id
    let euid = [entity, id].join(EUID_SEPARATOR)
    if (commodity) { // commodity will act as a filter
        euid = [euid, commodity].join(EUID_COMMODITY_SEPARATOR)
    }
    return euid
}

export function isEndEntity(euid) {
    return END_ENTITIES.includes(entityFromEUID(euid))
}

/**
 * Parse id on an entity
 *
 * @param {Number|String} id
 * @returns {Number|String} id
 */
export function parseId(id) {
    return isNaN(id) ? id : Number(id)
}

/**
 * Parse euid
 *
 * @param {String} euid
 * @param {Boolean} reverse
 * @returns {Object}
 */
export function parseEUID(euid, reverse = false) {
    // if ((!reverse || (euid.match(new RegExp(EUID_SEPARATOR, 'g')) || []).length === 1) && euid.includes(EUID_COMMODITY_SEPARATOR)) {
    //     euid = euid.split(EUID_COMMODITY_SEPARATOR)[0]
    // }
    // The above check has been moved after splitting the euid by the euid_separator in order
    // to not lose whatever is after the euid_commodity_separator, updated check is:
    // if(euid.includes(EUID_COMMODITY_SEPARATOR))
    //     euid = euid.split(EUID_COMMODITY_SEPARATOR)[0]

    const items = euid.split(EUIDS_SEPARATOR).map(euid => {
        if (euid.includes(EUID_COMMODITY_SEPARATOR)) { euid = euid.split(EUID_COMMODITY_SEPARATOR)[0] }
        const [entity, id] = euid.split(EUID_SEPARATOR)
        return {
            entity,
            id: parseId(id),
        }
    })
    if (reverse) {
        return items.reduceRight((result, value) => {
            if (!result) return value
            result.parent = value
            return result
        })
    }
    return items.reduce((result, value) => {
        if (!result) return value
        result.child = value
        return result
    })
}

/**
 * Parse euids
 * @param {Array} euids
 * @param {Object} options
 * @returns
 */
export function parseEUIDs(euids, { entities = [], excludeEntities = [], reverse = false } = {}) {
    let items = euids.map(euid => parseEUID(euid, reverse))
    if (entities.length > 0) {
        items = items.filter(({ entity }) => entities.includes(entity))
    } else if (excludeEntities.length > 0) {
        items = items.filter(({ entity }) => !excludeEntities.includes(entity))
    }
    return items
}

/**
 * Get entity id from euid string
 *
 * Atm we have the following possible formats:
 * entity--id
 * entity--id__entity--id
 *
 * @param {String} euid
 * @param {Boolean} reverse
 */
export function idFromEUID(euid, reverse = false) {
    const { id } = parseEUID(euid, reverse)
    return id
}

export function entityFromEUID(euid, reverse = false) {
    const { entity } = parseEUID(euid, reverse)
    return entity
}

export function createTreeEUID(entities) {
    return entities.map(createEUID).join(EUIDS_SEPARATOR)
}

export function blankPreset(extra = {}) {
    return {
        scope: null,
        id: null, // preset id
        name: '', // preset name
        type: 'virtual', // group | virtual
        siteIds: [], // site ids
        meterIds: [], // meter ids
        circuitIds: [], // circuit ids
        updatedAt: null,
        access: Preset.ACCESS_TYPES.PRIVATE,
        isHidden: false,
        ...eliberate(extra),
    }
}

export function filterDataByInterval({ data, period }) {
    return filter(data, e => {
        return e[0] >= period.start && e[0] <= period.end
    })
}

/**
 * Get the amount of milliseconds of a certain duration that must be added to a given dateTime
 * @param resolution amount of seconds in a year|month|etc
 * @param dateTime The dateTime to use as start-point. We need this to calculate correct amount of sec in month
 * @returns {number}
 */
export function getResolutionNumericValue(resolution, dateTime) {
    let resolutionNumericValue
    const msDateTime = moment(dateTime).format('x')
    const msNow = moment().format('x')

    if (resolution === 'month') {
        resolutionNumericValue = millisecondInMonth(dateTime)
    } else {
        resolutionNumericValue = resolution * 1000
    }
    if (msDateTime + resolutionNumericValue > msNow) {
        // we return the amount of ms from the given date till now
        return msNow - msDateTime
    }
    return resolutionNumericValue
}

export function millisecondInMonth(dateTime) {
    return moment(dateTime).daysInMonth() * 60 * 60 * 24 * 1000
}

export function getPeaksByMonth(data, output = 'timestamp') {
    const temp = {}
    each(data, ([dateTime, value]) => {
        const month = moment(dateTime).format('YYYYMM')
        temp[month] = temp[month] || [dateTime, value]
        if (temp[month][1] < value) {
            temp[month] = [dateTime, value]
        }
    })
    let peaks = {}
    if (output === 'timestamp') {
        each(values(temp), ([dateTime, value]) => {
            peaks[dateTime] = [dateTime, value]
        })
    } else {
        peaks = temp
    }
    return peaks
}

export function circuitIdFromTraceId(traceId) {
    return parseId(last(traceId.split('_')))
}

export function parseTraceId(traceId = '') {
    if (traceId.includes('consolidation')) return
    // eslint-disable-next-line prefer-const
    let [, unit, resolution, id, comparison] = traceId.match(/([1-3A-Z_]+)_([0-9A-Za-z]{1,5})_([0-9V]+)_*([a-z]*)/)
    id = parseId(id)
    return {
        unit,
        resolution,
        id,
        circuitId: id, // temporary
        isComparison: Boolean(comparison),
    }
}

export function infoFromTraceId(traceId) {
    const separator = traceId.includes(EUIDS_SEPARATOR) ? EUIDS_SEPARATOR : '_' // temporary fallback
    const info = traceId.split(separator)
    return {
        unit: info[0],
        resolution: info[1],
        id: info[2],
    }
}

/**
 * Filter out children of entities that ads up to the consolidation
 * eg. if a parent meter / entity has data it contains children data too
 *
 * @returns {Array} entities
 * @param entities
 * @param {Object} period
 */
export function consolidateEntities(entities, period) {
    const start = moment(period.start)
    const end = moment(period.end)
    const entityIds = getIds(entities)
    const metersIds = entities
        .filter(e => e.isMainIncomer && start.isSameOrAfter(e.startTime))
        .map(e => e.meterId)

    return entities.filter(entity => {
        // skip entities that have already a parent selected
        if (
            entity.parentId &&
            entityIds.includes(entity.parentId) &&
            start.isSameOrAfter(entity.parent.startTime)
        ) {
            return false
        }

        // skip entities that have their meter selected
        if (!entity.isMainIncomer && entity.meterId && metersIds.includes(entity.meterId)) {
            return false
        }

        // skip entities that are parents of entities and not in time range
        if (entity.childrenCount > 0 && (start.isSameOrAfter(entity.endTime) || end.isSameOrBefore(entity.startTime))) {
            return false
        }

        return true
    })
}

/**
 * All pages entities control function method
 *
 * @param {*} param0
 * @returns {Array} entities
 */
export function pageEntities({ context }) {
    const { route, $config } = context
    const customPath = route.meta && route.meta[0] && route.meta[0].customPath
        ? route.meta[0].customPath
        : route.path
    const path = context.localePath(customPath, context.i18n.fallbackLocale)
    if (!path) return []

    // Get Entities List By Page
    const entities = PAGE_ENTITIES(path, $config, route) || []
    if (!entities.length) return []
    return entities
}

export function entityAtSingular(entity) {
    return pluralize.singular(entity)
}

/**
 * Get supported commodities for an array of sites
 *
 * @param   {Site[]}  sites
 *
 * @return  {String[]}
 */
export function sitesSupportedCommodities(sites = []) {
    const commoditiesSet = new Set()
    for (const site of sites) {
        const meters = site.meters || []
        const groupByCommodity = groupBy(meters, 'commodity')
        for (const c of DASHBOARD_BY_COMMODITY) {
            if (groupByCommodity[c] && groupByCommodity[c].length > 0) { commoditiesSet.add(c) }
        }
        if (commoditiesSet.size > 1) { commoditiesSet.add() }
        if (commoditiesSet.size > 2) { break }
    }

    return [...commoditiesSet].reverse()
}

/**
 * Calculate the entities ids to send to the readings-api
 * if (scope):
 * * for circuits or pipes it returns the ids
 * * for sites and meters it returns the main incomers ids
 * else it returns an object with named entities
 *
 * @param   {Array} entities (clamps, meters, sites)
 * @param   {String} scope ['electricity', 'gas', 'water']
 * @param   {Boolean} onlyClamps
 *
 * @return  {Object} {clampIds, siteIds?, meterIds?}
 */
export function calculateRequestEntitiesIds(entities = [], scope) {
    const clampIds = []
    const siteIds = []
    entities.forEach(item => {
        if (item.id[0] === 'V') return
        if (['clamps'].includes(item.entity)) {
            clampIds.push(item.id)
        } else if (scope) {
            clampIds.push(...item.getChildren(scope).reduce((res, m) => {
                if (['meters'].includes(m.entity)) {
                    const mainIncomer = m.children.reduce((result, child) => {
                        if (child.isMainIncomer) {
                            result.push(child.id)
                        }
                        return result
                    }, [])
                    res.push(mainIncomer[0])
                } else if (m.isMainIncomer) {
                    res.push(m.id)
                }
                return res.filter(v => v)
            }, []))
        } else {
            siteIds.push(item.id)
        }
    })
    return { clampIds, siteIds }
}

export function getClampFromEuid(euid, model, commodity) {
    const e = parseEUID(euid, true)
    if (e?.entity && e?.id) {
        if (e.entity.includes('meters')) {
            return model.query().where('meterId', Number(e.id)).where('isMainIncomer', true).first()
        } else if (e.entity.includes('sites')) {
            const c = model.query().where('siteId', Number(e.id)).where('isMainIncomer', true).where('commodity', commodity).first()
            return { ...c, siteId: c.siteId, site: c.site, fromSite: true }
        } else if (e.entity === 'presets') {
            return Preset.find(e.id)
        } else {
            return model.find(e.id)
        }
    }
}

/**
 * Sorting reducer
 * - sort tree items alphabetically
 * - sorting is done at the component level for performance reasons
 *
 * @param {String} sortKey
 * @returns {(items, items) =>  items}
 */
export function sortByNameReducer(sortKey) {
    return (items, item) => {
        if (item.children && item.children.length > 0) {
            item = {
                ...item,
                children: item.children.reduce(sortByNameReducer(sortKey), []),
            }
        }
        items.push(item)
        return items.sort(
            (a, b) => a[sortKey].localeCompare(
                b[sortKey], undefined, { sensitivity: 'base', numeric: true },
            ),
        )
    }
}

/**
 * Sorting reducer
 * - sort tree items by numerical value
 * - sorting is done at the component level for performance reasons
 *
 * @param {Array} items
 * @param {Object} item
 * @returns {Array} items
 */
export function sortByNumericalValue(items, sortKey) {
    const sortedItems = items.sort((a, b) => a[sortKey] - b[sortKey])
    sortedItems.forEach(item => {
        if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => a[sortKey] - b[sortKey])
        }
    })
    return sortedItems
}

export function addScopeToPayload(payload, commodity = null) {
    if (commodity) payload.scopes = [commodity?.toUpperCase()]
}

export function transformEntitiesByCommodity(state) {
    return Object.fromEntries(Object.entries(state).map(([k, obj]) => {
        const isVirtual = k.includes('--virtual')
        const activeNew = (obj.active ?? []).map(item => {
            const { entity, id } = parseEUID(item)
            const [commodity, newEntity] = findCommodityAndOppositeState(entity)
            if (commodity && newEntity) {
                return createEUID({ entity: newEntity, id })
            }
            return item
        })

        const selectedNew = (obj.selected ?? []).map(item => {
            const { entity, id } = parseEUID(item)
            const [commodity, newEntity] = findCommodityAndOppositeState(entity)
            if (commodity && newEntity) {
                return createEUID({ entity: newEntity, id })
            }
            return item
        })

        return [k, {
            type: ['all', 'virtual'].includes(obj.type) ? obj.type : (isVirtual ? 'virtual' : 'all'),
            active: activeNew,
            selected: selectedNew,
        }]
    }))
}
