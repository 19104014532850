import { uniq, differenceBy, uniqBy, flatten } from 'lodash'

export const state = {
    selected: [],
    selectedSites: [],
}

export const getters = {
    allSources(_state, getters) {
        return getters.query().get()
    },

    assets(_state, getters) {
        return getters
            .query()
            .where(({ actions }) => actions?.includes('addData'))
            .get()
    },

    tree: (_state, getters, rootState) => (tab = Number(rootState.carbon.tabIndex)) => {
        return getters.scopes.filter(({ scopeNumber }) => tab ? isNaN(scopeNumber) : !isNaN(scopeNumber))
    },

    sitesTree(_state, getters, rootState, rootGetters) {
        return rootGetters['entities/sites/all']().map(({ id, name, isUserGenerated, country }) => ({
            id,
            name,
            safeName: name,
            isSite: true,
            isUserGenerated,
            country,
            children: getters.tree, // filterBySiteIds(cloneDeep(getters['tree']), [id]),
        }))
    },

    scopes(_state, getters) {
        return getters
            .query()
            .where(e => {
                return !e.parentId
            })
            .get()
    },

    categories(_state, getters, _rootState, rootGetters) {
        return flatten(getters.scopes.map(({ children }) => children))
            .filter(({ scopeNumber }) => rootGetters['carbon/isScopesTab'] ? !isNaN(scopeNumber) : isNaN(scopeNumber))
    },

    categoryIds(_state, getters) {
        return getters.categories.map(({ id }) => id)
    },

    selectedItems: (state, getters, rootState) => (tab = Number(rootState.carbon.tabIndex)) => {
        return state.selected.filter(({ scopeNumber }) => tab ? isNaN(scopeNumber) : !isNaN(scopeNumber))
    },

    selectedIds(_state, getters, rootState) {
        return getters.selectedItems().map(i => i.$id)
    },

    selectedLeaves(_state, getters, rootState) {
        return getters.selectedItems().filter(({ children }) => !children.length).map(i => i.$id)
    },

    compactedIds(_state, getters, rootState) {
        if (getters.selectedItems().length === 0 || getters.selectedItems().length === getters.all().length) {
            return getters.tree().map(({ id }) => id)
        }
        function recursive(items) {
            const ids = []
            let stillSiblingsAround = false
            items.forEach(item => {
                if (ids.map(({ id }) => id).includes(item.parentId)) {
                    return
                }
                // console.log(item.name)
                // check if all the siblings are selected
                const unselectedSiblings = differenceBy(item.siblings, getters.selectedItems(), 'id')
                if (unselectedSiblings.length === 0) {
                    // console.log('all siblings selected')
                    ids.push(item.parent)
                    stillSiblingsAround = true
                } else {
                    ids.push(item)
                }
            })
            return stillSiblingsAround ? recursive(ids) : ids
        }
        return uniqBy(recursive(getters.selectedItems()), 'id').map(({ id }) => id)
    },

    tags(state, getters) {
        return getters.all().reduce((acc, val) => {
            acc.push(...val.tags)
            return acc
        }, [])
    },

    itemByTag: (state, getters) => tag => {
        return getters
            .query()
            .where('tags', tags => tags.includes(tag))
            .first()
    },

    deliveryVehicles(state, getters) {
        return getters.query().where('parentId', 'at-vehicle-hgv').orWhere('parentId', 'at-vehicle-van').get()
    },
}

export const actions = {
    toggleItemSelection({ commit, state, getters, rootState }, { id }) {
        let selected = []
        if (!id) {
            commit('SET_SELECTED', selected)
            return
        }
        const item = getters.query().find(id)
        if (!item) return
        const i = state.selected.findIndex(e => e.id === item.id)
        if (i >= 0) {
            // check if parent is selected too
            const ancestorIds = item.ancestors.map(({ id }) => id)
            const descendantIds = item.descendants.map(({ id }) => id)
            selected = uniq(
                state.selected.filter(
                    ({ id }, k) => k !== i && !ancestorIds.includes(id) && !descendantIds.includes(id),
                ),
            )
        } else {
            // also add all the children
            selected = uniq([...state.selected, item, ...item.descendants])
            // if all the siblings are selected, select the parent too
            const unselectedSiblings = differenceBy(item.siblings, selected, 'id')
            if (!unselectedSiblings.length && item.parent) {
                selected.push(item.parent)
            }
        }
        commit('SET_SELECTED', selected)
    },
    toggleSiteSelection({ commit }, selectedIds = []) {
        commit('SET_SELECTED_SITES', selectedIds)
    },
}

export const mutations = {
    SET_SELECTED(state, selected) {
        state.selected = selected
    },
    SET_SELECTED_SITES(state, selected) {
        state.selectedSites = selected
    },
}
