import { isString } from 'lodash'
import { differenceInDays, DAY, WEEK, MONTH, YEAR } from './date'

export const VALID_RESOLUTIONS = {
    MINUTE: 60,
    _5_MINUTES: 5 * 60,
    _15_MINUTES: 15 * 60,
    _30_MINUTES: 30 * 60,
    HOUR: 60 * 60,
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    YEAR: 'year',
}

export const MINUTE_RESOLUTION_PERIOD = VALID_RESOLUTIONS.HOUR * 24 * 7
export const BASELINE_RESOLUTION = VALID_RESOLUTIONS._15_MINUTES

export const AVAILABLE_RESOLUTIONS = [
    {
        value: 60,
        text: '1 Minute',
        description: 'This resolution will be automatically applied if the visible interval is 1 week or less',
    },
    {
        value: 900,
        text: '15 Minutes',
    },
    {
        value: 1800,
        text: '30 Minutes',
    },
    {
        value: 3600,
        text: 'Hour',
    },
    {
        value: 86400,
        text: 'Day',
    },
    // {
    //     value: 'week',
    //     text: 'Week',
    // },
    {
        value: 'month',
        text: 'Month',
    },
    // {
    //     value: 'year',
    //     text: 'Year',
    // },
]

export function getAvailableResolutions(sampleRate) {
    return AVAILABLE_RESOLUTIONS.filter(r => r.value >= sampleRate || isString(r.value))
}

export function getResolutionValue(resolution) {
    switch (resolution) {
        case 'day': return DAY
        case 'week': return WEEK
        case 'month': return MONTH
        case 'year': return YEAR
        default: return resolution
    }
}

export function get1minResolutionForAPI(resolution) {
    return resolution === VALID_RESOLUTIONS.MINUTE ? BASELINE_RESOLUTION : resolution
}

/**
 * Dynamic resolution - based on start / end period dates
 *
 * @param {Date} dateLeft
 * @param {Date} dateRight
 * @returns {Number|string} resolution
 */
export function dynamicResolution(dateLeft, dateRight) {
    const daysDiff = differenceInDays(dateLeft, dateRight)

    switch (true) {
        case daysDiff <= 1:
            return VALID_RESOLUTIONS.HOUR
        // case daysDiff <= 14:
        //     return HALF_DAY
        case daysDiff <= 30 * 6: // less than 6 months
            return VALID_RESOLUTIONS.DAY
        // case daysDiff <= 30 * 9: // ~ less than 9 months
        //     return VALID_RESOLUTIONS.WEEK
        case daysDiff <= 30 * 24: // ~ less than 2 years
            return VALID_RESOLUTIONS.WEEK
        default:
            return VALID_RESOLUTIONS.MONTH
        // return VALID_RESOLUTIONS.YEAR
    }
}

/**
 * Dynamic resolution - type 2
 * - used for net zero / carbon
 *
 * @param {Date} dateLeft
 * @param {Date} dateRight
 * @returns {Number|string} resolution
 */
export function dynamicResolution2(dateLeft, dateRight) {
    const daysDiff = differenceInDays(dateLeft, dateRight)

    switch (true) {
        // case daysDiff <= 1:
        //     return VALID_RESOLUTIONS.HOUR
        case daysDiff <= 14: // less than 2 weeks
            return VALID_RESOLUTIONS.DAY
        case daysDiff <= 30 * 4: // ~ less than 4 months
            return VALID_RESOLUTIONS.WEEK
        default:
            return VALID_RESOLUTIONS.MONTH
    }
}
